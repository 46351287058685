import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCalendarApp, ScheduleXCalendar } from "@schedule-x/react";
import { createViewMonthGrid } from "@schedule-x/calendar";
import { createEventsServicePlugin } from "@schedule-x/events-service";
import { useColorMode } from "@repleek/mui";

import "@schedule-x/theme-default/dist/index.css";
import "./style.css";
import { PlanningCard } from "@/components";
import PlanningDrawer from "./PlanningDrawer";
import { socialIcons } from "@/components/features/account/SocialDetail";
import { useRecoilState, useRecoilValue } from "recoil";
import { postQueryParams, postSelector } from "@/store/post";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import dayjs from "dayjs";
import { notionOptionByValue } from "@/store/notion";
import { CiVideoOn } from "react-icons/ci";
import { IconButton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";
import { useRefreshPlanning } from "@/hooks/planning";

type Props = {
  id: string;
  status?: Exclude<Post["status"], "not-started">[];
};

const Planning: React.FC<Props> = ({ id, status }) => {
  const plugins = [createEventsServicePlugin()];

  const { mode } = useColorMode();
  const [selectedPost, setSelectedPost] = useState<Post | undefined>();
  const [query, setQuery] = useRecoilState(postQueryParams);

  const posts = useRecoilValue(
    postSelector({
      ...query,
      id,
      status,
    })
  );

  const events = useMemo(
    () =>
      posts?.map(({ scheduledSendTime, ...rest }) => ({
        start: scheduledSendTime,
        end: scheduledSendTime,
        scheduledSendTime,
        ...rest,
      })),
    [posts]
  ) as any[];
  const onSelectedDateUpdate = useCallback(
    (date: string) => {
      setQuery((query) => ({
        ...query,
        date: dayjs(date).startOf("M").format(),
      }));
    },
    [setQuery]
  );

  const onEventClick = useCallback((post: Post) => {
    setSelectedPost(post);
  }, []) as () => void;

  const calendar = useCalendarApp(
    {
      isDark: mode === "dark",
      locale: "fr-FR",
      views: [createViewMonthGrid()],
      events,
      callbacks: {
        onEventClick,
        onClickDate(date: any) {
          console.log("onClickDate", date); // e.g. 2024-01-01
        },
        onSelectedDateUpdate,
        onClickAgendaDate(date: string) {
          console.log("onClickAgendaDate", date); // e.g. 2024-01-01
        },
      },
      minDate: dayjs().startOf("M").subtract(2, "M").format("YYYY-MM-DD"),
      maxDate: dayjs().endOf("M").add(2, "M").format("YYYY-MM-DD"),
    },
    plugins
  );

  useEffect(() => {
    // get all events
    const allEvents = calendar.eventsService.getAll() as any[];
    events?.forEach((event) => {
      const eventIdenx = allEvents.findIndex(({ id }) => id === event.id);
      if (eventIdenx === -1) {
        calendar.eventsService.add(event);
      } else {
        calendar.eventsService.update(event);
      }
    });
  }, [calendar.eventsService, events]);

  useEffect(() => {
    calendar.setTheme(mode === "dark" ? "dark" : "light");
    setQuery((query) => ({
      ...query,
      status,
      id,
    }));
  }, [calendar, id, mode, setQuery, status]);

  return (
    <>
      <ScheduleXCalendar
        calendarApp={calendar}
        customComponents={{
          monthGridEvent: TimeGridEvent,
          headerContentRightPrepend: RefreshPlanningButton,
        }}
      />
      <PlanningDrawer
        post={selectedPost}
        onClose={() => setSelectedPost(undefined)}
        id={id}
        onRemovePost={(id: number) => calendar.eventsService.remove(id)}
      />
    </>
  );
};

export default Planning;

const TimeGridEvent: React.FC<{ calendarEvent: Post }> = (props) => {
  const { title, socialProfileIs, status, video, messages } =
    props.calendarEvent;
  const option = useRecoilValue(
    notionOptionByValue({
      key: status,
      collection: "livrables",
    })
  );

  const socials = useMemo(() => {
    const socials: any[] = [];
    if (socialProfileIs) {
      const { facebook, instagram, linkedin, tiktok } = socialProfileIs;
      if (facebook) socials.push("facebook");
      if (instagram) socials.push("instagram");
      if (linkedin) socials.push("linkedin");
      if (tiktok) socials.push("tiktok");
    }
    return socials;
  }, [socialProfileIs]);

  const messagesStatus = useMemo<"open" | "closed" | undefined>(() => {
    if (!messages?.length) return undefined;
    if (messages.find(({ status }) => status === "open")) return "open";
    return "closed";
  }, [messages]);

  return (
    <PlanningCard
      className="planning-card"
      socials={{
        children: <SocialIcon icons={socials} video={!!video} />,
      }}
      state={{
        className: option?.color,
        label: option?.label,
        hidden: !option,
        size: "small",
        variant: "outlined",
      }}
      name={{
        text: title,
      }}
      message={{ hidden: !messagesStatus, className: messagesStatus }}
    />
  );
};

const SocialIcon: React.FC<{
  icons: (keyof typeof socialIcons)[];
  video?: boolean;
}> = (props) => {
  const icons = useMemo(
    () => props.icons.map((icon) => socialIcons[icon]),
    [props.icons]
  );
  return (
    <>
      {props.video && <CiVideoOn style={{ fontSize: "18px" }} />}
      {icons.map(
        ({ icon: { icon: Icon, style }, title }) =>
          Icon && <Icon key={title} style={{ ...style, fontSize: "18px" }} />
      )}
    </>
  );
};

const RefreshPlanningButton: React.FC = () => {
  const refreshPost = useRefreshPlanning();

  return (
    <IconButton onClick={refreshPost}>
      <IoMdRefresh />
    </IconButton>
  );
};
