/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Chip,
  ChipProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { GoDotFill } from "react-icons/go";
import { FaCommentDots } from "react-icons/fa6";
// #endregion

// #region Type Definition
type PlanningCardProps = Partial<BoxProps> & {
  name: TypographyProps & {},
  state: Partial<ChipProps>,
  socials: Partial<BoxProps>,
  message: Partial<IconProps>,
};
// #endregion

// #region PlanningCardComponent
const PlanningCard: React.FC<PlanningCardProps> = (props) => {
  const { name, state, socials, message, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        minWidth: "132px",
        maxWidth: "250px",
        minHeight: "118px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "8px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "12px",
        paddingBottom: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        ":hover": {
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          userSelect: "none",
          cursor: "pointer",
        },
        ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        "@media (max-width: 700px)": { minWidth: "44px" },
      }}
      {...rootProps}
    >
      <Typography
        variant={"h2"}
        text={"Mon titre ici <span>Mon titre ici</span>"}
        gutterBottom={false}
        noWrap={false}
        className={"clamp-two-lines"}
        css={{
          fontSize: "12px",
          marginBottom: "12px",
          flexGrow: "1",
          "@media (max-width: 700px)": { display: "none" },
        }}
        {...name}
      />
      <Box
        tag={"div"}
        css={{
          marginBottom: "12px",
          gap: "5px",
          display: "flex",
          "@media (max-width: 700px)": {
            width: "100%",
            gap: "4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
        {...socials}
      />
      <Box
        tag={"div"}
        css={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Chip
          label={"Chip label"}
          avatar={false}
          icon={true}
          clickable={false}
          color={"default"}
          variant={"filled"}
          css={{ "@media (max-width: 700px)": { display: "none" } }}
          Icon={
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={GoDotFill}
              className={
                "MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
              }
            />
          }
          {...state}
        />
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={FaCommentDots}
          css={{ fontSize: "18px" }}
          {...message}
        />
      </Box>
    </Box>
  );
};

export default PlanningCard;
// #endregion
