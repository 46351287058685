/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Icon, Typography, TypographyProps } from "@repleek/mui";
import { IoMailUnread } from "react-icons/io5";
// #endregion

// #region Type Definition
type StatusInsightProps = Partial<BoxProps> & {
  value: TypographyProps & {},
  label: TypographyProps & {},
};
// #endregion

// #region StatusInsightComponent
const StatusInsight: React.FC<StatusInsightProps> = (props) => {
  const { value, label, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        width: "178px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "8px",
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "8px",
        paddingBottom: "8px",
        display: "flex",
        flexDirection: "column",
        ".mui-dark &": {
          boxShadow:
            "rgba(255, 255, 255, 0.02) 0px 1px 3px 0px  , rgba(255, 255, 255, 0.15) 0px 0px 0px 1px ",
        },
        "@media (max-width: 480px)": { width: "160px" },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          gap: "8px",
          marginBottom: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={IoMailUnread}
          css={{
            padding: "4px",
            backgroundColor: "rgb(219, 193, 233)",
            fontSize: "24px",
            borderRadius: "16px",
          }}
        />
        <Typography
          variant={"h3"}
          text={"Nouveau"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "14px" }}
          {...label}
        />
      </Box>
      <Typography
        variant={"h1"}
        text={"150"}
        gutterBottom={false}
        noWrap={false}
        css={{
          fontSize: "38px",
          color: "rgba(82, 253, 122, 1)",
          flexGrow: "1",
        }}
        {...value}
      />
    </Box>
  );
};

export default StatusInsight;
// #endregion
