import React, { Suspense } from "react";

import Accounts from "@/components/features/account/Accounts";
import Layout, { PistacheLoader } from "@/components/layout";

const AccountsPage: React.FC = () => {
  return (
    <Layout title="Pistache - comptes" mode="ADMIN" name="Comptes">
      <Suspense fallback={<PistacheLoader />}>
        <Accounts />
      </Suspense>
    </Layout>
  );
};

export default AccountsPage;
