import { Box } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { IoPlay } from "react-icons/io5";

type Props = {
  url?: string;
  background?: string;
};

const Video: React.FC<Props> = (props) => {
  const { background, url } = props;
  const [playing, setPlaying] = useState(false);

  const httpUrl = useMemo(() => {
    if (!url) return undefined;
    if (url.startsWith("http")) return url;
    return `https://${url}`;
  }, [url]);

  const onStartVideo = useCallback(() => {
    setPlaying(true);
  }, []);

  return playing ? (
    <ReactPlayer
      url={httpUrl}
      width={308}
      height={546}
      playing={playing}
      controls
      onEnded={() => setPlaying(false)}
      config={{
        file: {
          attributes: {
            controlsList: "nodownload nofullscreen noremoteplayback",
            disablePictureInPicture: true,
          },
        },
      }}
    />
  ) : (
    <Box
      sx={{
        cursor: "pointer",
        position: "relative",
        // background: `url('${background}') center/cover no-repeat`,
        // width: 308,
        // height: 546,
      }}
      onClick={onStartVideo}>
      <img src={background} alt="" style={{ width: "100%" }} />
      <IoPlay
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          fontSize: "38px",
          transform: "translate(-50%, -50%)",
          background: "#ffffffcc",
          padding: "8px 8px 8px 10px",
          borderRadius: "38px",
          color: "#151419",
          zIndex: 1,
          cursor: "pointer",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
        }}
        onClick={onStartVideo}
      />
    </Box>
  );
};

export default Video;
