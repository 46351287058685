/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
} from "@repleek/mui";
import { RiRefreshLine } from "react-icons/ri";
// #endregion

// #region Type Definition
type LeadsListProps = Partial<ContainerProps> & {
  table: Partial<BoxProps>,
  categories: Partial<BoxProps>,
  resync: ButtonProps & {},
  datePicker: Partial<BoxProps>,
};
// #endregion

// #region LeadsListComponent
const LeadsList: React.FC<LeadsListProps> = (props) => {
  const { table, categories, resync, datePicker, ...rootProps } = props;

  return (
    <Container
      maxWidth={"lg"}
      disableGutters={true}
      fixed={false}
      css={{
        marginTop: "8px",
        "@media (max-width: 980px)": { paddingBottom: "8px" },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        className={"bg-white"}
        css={{
          padding: "8px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          borderRadius: "16px",
        }}
      >
        <Box
          tag={"div"}
          css={{
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            display: "flex",
            alignItems: "flex-start",
            "@media (max-width: 980px)": {
              gap: "8px",
              display: "flex",
              flexDirection: "column-reverse",
              flexWrap: "wrap",
              alignItems: "flex-end",
            },
          }}
        >
          <Box
            tag={"div"}
            css={{
              overflowX: "auto",
              gap: "14px",
              flexGrow: "1",
              paddingLeft: "8px",
              paddingRight: "8px",
              display: "flex",
              flexWrap: "wrap",
              "@media (max-width: 980px)": { width: "100%" },
            }}
            {...categories}
          />
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              "@media (max-width: 700px)": {
                width: "100%",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <Box tag={"div"} {...datePicker} />
            <Button
              text={"Sync"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={true}
              iconEnd={false}
              IconStart={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={RiRefreshLine}
                />
              }
              {...resync}
            />
          </Box>
        </Box>
        <Box tag={"div"} {...table} />
      </Box>
    </Container>
  );
};

export default LeadsList;
// #endregion
