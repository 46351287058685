/** Build with repleek */
// #region Import
import React from "react";

import {
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type EmptySheetProps = Partial<ContainerProps> & {
  action: ButtonProps & {},
};
// #endregion

// #region EmptySheetComponent
const EmptySheet: React.FC<EmptySheetProps> = (props) => {
  const { action, ...rootProps } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      className={"bg-white"}
      css={{
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        borderRadius: "16px",
        paddingLeft: "42px",
        paddingRight: "42px",
        paddingTop: "32px",
        paddingBottom: "32px",
      }}
      {...rootProps}
    >
      <Typography
        variant={"h3"}
        text={"Connectez Google Sheets pour gérer les leads Meta"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "22px", marginBottom: "12px" }}
      />
      <Typography
        variant={"body1"}
        text={
          "Automatisez la synchronisation des leads Meta directement avec Google Sheets. Gagnez du temps et centralisez les données pour un suivi simplifié."
        }
        gutterBottom={false}
        noWrap={false}
        css={{ marginBottom: "24px" }}
      />
      <Button
        text={"Configurer la connexion"}
        type={"button"}
        disabled={false}
        disableElevation={false}
        disableFocusRipple={false}
        disableRipple={false}
        fullWidth={false}
        size={"small"}
        variant={"contained"}
        iconStart={false}
        iconEnd={false}
        {...action}
      />
    </Container>
  );
};

export default EmptySheet;
// #endregion
