import React from "react";
import DateRange from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "./style.css";

export type DateRangePickerProps = {
  value?: DateRangeValue;
  onChange?: (value: DateRangeValue) => void;
};
type ValuePiece = Date | null;

export type DateRangeValue = ValuePiece | [ValuePiece, ValuePiece];

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
  const { value, onChange } = props;

  return <DateRange onChange={onChange} value={value} />;
};

export default DateRangePicker;
