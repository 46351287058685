import React, { useCallback } from "react";
import { Count } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { Button } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { Provider } from "@/interfaces";

type Props = {
  accountId: string;
  defaultPeriod?: string | Date;
  maxDate?: Dayjs;
};

const MounthPicker: React.FC<Props> = (props) => {
  const { accountId, defaultPeriod, maxDate } = props;
  const { control, setValue } = useFormContext();
  const [strapi, { loading }] = useStrapi();

  const setSocialInsights = useCallback(
    (type: Provider, value?: Count) => {
      if (value) {
        const {
          followers,
          impressions,
          interactions,
          posts,
          engagement,
          comments,
          reactions_likes,
        } = value;

        setValue(type, {
          followers: followers || 0,
          impressions: impressions || 0,
          interactions: interactions || 0,
          engagement: engagement || 0,
          posts: posts || 0,
          comments: comments || 0,
          reactions_likes: reactions_likes || 0,
        });
        toast(`${type} values are updated`, { type: "success" });
      } else toast(`Missing ${type} values`, { type: "error" });
    },
    [setValue]
  );

  const getSocialInsights = useCallback(
    async (period?: { start: string; end: string }) => {
      // recuperer la liste des statistics pour le mois selectioné
      if (!period) return undefined;

      const insights = await strapi?.get(
        "social-statistic/range/:accountId",
        {
          ":accountId": accountId,
        },
        { from: period.start, to: period.end }
      );

      if (insights && Object.keys(insights).length) {
        Object.entries(insights).forEach(([type, counts]) => {
          setSocialInsights(type as Provider, counts);
        });
      } else
        toast("Aucune statistique enregistrée pour la période sélectionnée.", {
          type: "error",
        });
    },
    [accountId, setSocialInsights, strapi]
  );

  const onSyncDate = useCallback(
    async (date?: Dayjs) => {
      if (date) {
        const period = {
          start: date.startOf("month").format("YYYY-MM-DD"),
          end: date.endOf("month").format("YYYY-MM-DD"),
        };

        await getSocialInsights(period);
        setValue(
          "name",
          `Compte rendu du mois de ${date.endOf("month").format("MMMM YYYY")}`
        );
      }
    },
    [getSocialInsights, setValue]
  );

  return (
    <Controller
      control={control}
      name="period"
      render={({
        field: { ref, onBlur, name, onChange, value = null, ...field },
      }) => (
        <>
          <MobileDatePicker
            inputRef={ref}
            label="Période"
            value={value}
            onChange={onChange}
            slotProps={{
              calendarHeader: {
                slotProps: {
                  nextIconButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                  previousIconButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                  switchViewButton: {
                    color: "secondary",
                    className: "no-shadow no-borders",
                  },
                },
              },
            }}
            localeText={{ cancelButtonLabel: "Annuler" }}
            views={["month", "year"]}
            disableFuture
            disabled={!!defaultPeriod}
            maxDate={maxDate}
            monthsPerRow={4}
            {...field}
          />
          <Button
            variant="contained"
            color="secondary"
            sx={{ padding: "6px 14px" }}
            disabled={!value || loading}
            onClick={() => onSyncDate(value)}>
            Synchroniser
          </Button>
        </>
      )}
    />
  );
};

export default MounthPicker;
