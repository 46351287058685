import React, { Suspense, useCallback, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { EmptySheet, LeadsList } from "@/components";
import { accountSelector } from "@/store/accounts";
import { useStrapi } from "@/providers/StrapiProvider";
import SheetModal from "@/components/features/leads/SheetModal";
import LeadsTable from "@/components/features/leads/LeadsTable";
import Categories from "@/components/features/leads/Categories";
import { PistacheLoader } from "@/components/layout";
import DateRangePicker, {
  DateRangeValue,
} from "@/components/fields/DateRangePicker";
import { leadsParamsQuery } from "@/store/leads";
import SkeletonCategory from "@/components/features/leads/SkeletonCategory";
import { authState } from "@/store/auth";

type Props = { id: string };

const Leads: React.FC<Props> = ({ id }) => {
  const [newConnexionModal, setNewConnexionModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useRecoilValue(authState);
  const account = useRecoilValue(accountSelector(id));
  const [{ range }, setLeadsParam] = useRecoilState(
    leadsParamsQuery(account?.id as number)
  );

  const [strapi] = useStrapi();

  const onResync = useCallback(async () => {
    if (account?.sheet?.sheetId) {
      setLoading(true);
      await strapi?.get("leads/google-sheet/:sheetId", {
        ":sheetId": account.sheet.sheetId,
      });
      setLoading(false);
    }
  }, [account?.sheet?.sheetId, strapi]);

  const onDateRangeChange = useCallback(
    (range: DateRangeValue) => {
      setLeadsParam((prev) => ({ ...prev, range }));
    },
    [setLeadsParam]
  );

  if (
    !account?.id ||
    (!account.sheet && user?.workspaces?.find(({ key }) => key !== "default"))
  )
    return null;

  return (
    <>
      {!account?.sheet ? (
        <EmptySheet action={{ onClick: () => setNewConnexionModal(true) }} />
      ) : (
        <LeadsList
          table={{
            children: loading ? (
              <PistacheLoader css={{ height: 450 }} />
            ) : (
              <LeadsTable account={account} />
            ),
          }}
          categories={{
            children: (
              <Suspense fallback={<SkeletonCategory />}>
                <Categories id={account.id} />
              </Suspense>
            ),
          }}
          datePicker={{
            children: (
              <DateRangePicker value={range} onChange={onDateRangeChange} />
            ),
          }}
          resync={{ onClick: onResync, disabled: loading }}
        />
      )}
      <SheetModal
        open={newConnexionModal}
        accountId={id}
        onClose={() => setNewConnexionModal(false)}
      />
    </>
  );
};

export default Leads;
