import React, { ReactNode, useMemo, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import Reviews from "./reviews";
import Posts from "./posts";
import Media from "./media";
import { GmbLayout, MisConfiguration, NotFound } from "@/components";
import { Button } from "@mui/material";
import { accountSelector } from "@/store/accounts";
import { useRecoilValue } from "recoil";
import GMBSelectModal from "@/components/features/gmb/GMBSelectModal";

type Tab = "reviews" | "media" | "posts";

const GoogleBusiness: React.FC = () => {
  const { id, type } = useParams() as { type: Tab; id: string };
  const [openGmbSelect, setOpenGmbSelect] = useState(false);
  const account = useRecoilValue(accountSelector(id));

  const children = useMemo(() => {
    // cehck if account is linked with a gmb
    if (!account?.gmb_page)
      return (
        <MisConfiguration
          selecteGMB={{ onClick: () => setOpenGmbSelect(true) }}
        />
      );
    switch (type) {
      case "media":
        return <Media />;
      case "posts":
        return <Posts />;
      case "reviews":
        return <Reviews />;
    }
  }, [account?.gmb_page, type]);

  const menu = [
    {
      label: "Avis",
      href: `/account/${id}/gmb`,
    },
    {
      label: "Photos & vidéo",
      href: `/account/${id}/gmb/media`,
    },
    {
      label: "Actualités",
      href: `/account/${id}/gmb/posts`,
    },
  ];

  if (!account?.workspace?.gmb_account_manager) return <NotFound />;

  return (
    <>
      <GmbLayout
        tabMenu={{
          children: (
            <>
              {menu.map(({ href, label }) => (
                <NavLink key={href} to={href} end>
                  {({ isActive }) => (
                    <Button
                      sx={{
                        justifyContent: "start",
                        borderColor: "transparent",
                        padding: "8px 16px",
                        borderRadius: "10px",
                        boxShadow: "none",
                        ...(isActive && {
                          border: "1px solid",
                        }),
                        mb: 0.5,
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                      fullWidth
                      color="secondary"
                      size="small"
                      variant="outlined">
                      {label}
                    </Button>
                  )}
                </NavLink>
              ))}
            </>
          ),
        }}
        tabContent={{ children }}
      />
      <GMBSelectModal
        open={openGmbSelect}
        onClose={() => setOpenGmbSelect(false)}
        wkId={account.workspace.id as number}
      />
    </>
  );
};

export default GoogleBusiness;
