/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Image,
  ImageProps,
  Paper,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type WorkspaceDetailProps = Partial<ContainerProps> & {
  editBtn: ButtonProps & {},
  name: TypographyProps & {},
  informations: ButtonProps & {},
  users: ButtonProps & {},
  accounts: ButtonProps & {},
  logo: Partial<ImageProps>,
};
// #endregion

// #region WorkspaceDetailComponent
const WorkspaceDetail: React.FC<WorkspaceDetailProps> = (props) => {
  const { editBtn, name, informations, users, accounts, logo, ...rootProps } =
    props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{ marginTop: "16px", marginBottom: "16px" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={0}
        css={{
          padding: "24px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          gap: "12px",
          borderRadius: "22px",
          display: "flex",
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Box tag={"div"} css={{ width: "130px", height: "130px" }}>
          <Image
            alt={"Image description for better SEO"}
            src={"https://picsum.photos/200/300"}
            dynamic={true}
            css={{ width: "100%", height: "100%" }}
            {...logo}
          />
        </Box>
        <Box
          tag={"div"}
          css={{
            flexGrow: "1",
            paddingLeft: "16px",
            borderLeftWidth: "1px",
            borderLeftStyle: "solid",
            borderLeftColor: "rgb(212, 212, 212)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box tag={"div"} css={{ flexGrow: "1" }}>
            <Typography
              variant={"h3"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ maxWidth: "480px", fontSize: "26px", marginBottom: "8px" }}
              {...name}
            />
            <Button
              text={"Modifier"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"contained"}
              iconStart={false}
              iconEnd={false}
              css={{
                paddingLeft: "12px",
                paddingRight: "12px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
              {...editBtn}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              marginTop: "8px",
              ".action-tab": {
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px ",
                borderStyle: "none",
                marginRight: "4px",
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              },
              ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
            }}
          >
            <Button
              text={"Informations"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...informations}
            />
            <Button
              text={"membres"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...users}
            />
            <Button
              text={"comptes"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              iconStart={false}
              iconEnd={false}
              className={"action-tab"}
              {...accounts}
            />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default WorkspaceDetail;
// #endregion
