import React, { Suspense } from "react";
import { useRecoilValue } from "recoil";
import Layout, { PistacheLoader } from "@/components/layout";
import PlanningComponent from "../admin/account/planning";
import { accountUserState } from "@/store/auth";

const Planning: React.FC = () => {
  return (
    <Layout
      mode="CUSTOMER"
      title="Pistache - Planning"
      name="Planning de publication">
      <Suspense
        fallback={<PistacheLoader css={{ height: "calc(100vh - 110px)" }} />}>
        <Component />
      </Suspense>
    </Layout>
  );
};

export default Planning;

const Component: React.FC = () => {
  const account = useRecoilValue(accountUserState);
  if (!account?.id) return null;

  return (
    <PlanningComponent
      id={account.id.toString()}
      status={["done", "validated", "checked"]}
    />
  );
};
