import { useMemo } from "react";
import { Account, SocialReport } from "@/modules/strapi-sdk/lib/interfaces";

export const useSocialAccess = (configuration?: Account["configuration"]) => {
  return useMemo(() => {
    if (configuration) {
      return configuration;
    }
    return { facebook: true, instagram: true, tiktok: true, linkedin: true };
  }, [configuration]);
};

export const getSocialAccess = (
  configuration?: Account["configuration"]
): (keyof SocialReport)[] => {
  if (configuration) {
    const socials: (keyof SocialReport)[] = [];
    if (configuration.facebook) socials.push("facebook");
    if (configuration.instagram) socials.push("instagram");
    if (configuration.tiktok) socials.push("tiktok");
    if (configuration.linkedin) socials.push("linkedin");
    return socials;
  }
  return ["facebook", "instagram", "tiktok", "linkedin"];
};
