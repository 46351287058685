import { NotFound, WorkspaceDetail } from "@/components";
import Layout, { PistacheLoader } from "@/components/layout";
import { workspaceById } from "@/store/workspace";
import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Informations from "./informations";
import { MakeRequired } from "@/interfaces";
import { Workspace as WorkspaceType } from "@/modules/strapi-sdk/lib/interfaces";

const Workspace: React.FC = () => {
  return (
    <Layout title="Pistache - workspace" mode="ADMIN" name="Workspace">
      <Suspense fallback={<PistacheLoader />}>
        <Component />
      </Suspense>
    </Layout>
  );
};

const Component: React.FC = () => {
  const { page, id } = useParams() as {
    page: "informations" | "users" | "accounts";
    id: string;
  };

  const workspace = useRecoilValue(workspaceById(parseInt(id)));
  if (!workspace?.id) return <NotFound />;

  return (
    <>
      <WorkspaceDetail
        logo={{ src: workspace.avatar?.url }}
        editBtn={{ hidden: true }}
        name={{ text: workspace.name }}
        informations={{
          variant: page === "informations" ? "contained" : "text",
          href: `/workspace/${id}/informations`,
        }}
        users={{
          variant: page === "users" ? "contained" : "text",
          href: `/workspace/${id}/users`,
        }}
        accounts={{
          variant: page === "accounts" ? "contained" : "text",
          href: `/workspace/${id}/accounts`,
        }}
      />

      {page === "informations" && (
        <Informations
          workspace={workspace as MakeRequired<WorkspaceType, "id">}
        />
      )}
    </>
  );
};

export default Workspace;
