import { ReactElement } from "react";
import Home from "./Home";
import Login from "./common/Login";
import ResetPassword from "./common/ResetPassword";
import NewAccount from "./admin/new-account";
import Account from "./admin/account";
import PageNotFound from "./common/PageNotFound";
import Oauth from "./common/Oauth";
import NewReport from "./admin/NewReport";
import Reporting from "./admin/Reporting";
import Users from "./admin/Users";
import Accounts from "./admin/Accounts";

import UserPlanning from "./user/Planning";
import UserLeads from "./user/Leads";

import {
  LuLayoutDashboard,
  LuUsers,
  LuStore,
  LuFolderClosed,
  LuCalendarRange,
} from "react-icons/lu";

import Workspace from "./admin/workspace";

const pages: { path: string; page: ReactElement }[] = [
  {
    path: "*",
    page: <PageNotFound />,
  },
  {
    path: "/",
    page: <Home />,
  },
  {
    path: "/login",
    page: <Login />,
  },
  {
    path: "/reset-password",
    page: <ResetPassword />,
  },
  {
    path: "/oauth/:provider",
    page: <Oauth />,
  },
  // ADMIN
  {
    path: "/accounts",
    page: <Accounts />,
  },
  {
    path: "/new-account",
    page: <NewAccount />,
  },
  {
    path: "/users",
    page: <Users />,
  },
  {
    path: "/account/:id",
    page: <Account />,
  },
  {
    path: "/account/:id/:page/:type?",
    page: <Account />,
  },
  {
    path: "/edit-account/:id",
    page: <NewAccount />,
  },
  {
    path: "/:accountId/new-reporting",
    page: <NewReport />,
  },
  {
    path: "/:accountId/reporting/:id",
    page: <Reporting />,
  },
  {
    path: "/:accountId/edit-reporting/:id",
    page: <NewReport />,
  },
  // CUSTOMER
  {
    path: "/planning",
    page: <UserPlanning />,
  },
  {
    path: "/leads",
    page: <UserLeads />,
  },
  {
    path: "/workspace/:id/:page",
    page: <Workspace />,
  },
];

export const navigations = {
  admin: [
    {
      label: "Dashboard",
      href: "/",
      icon: LuLayoutDashboard,
    },
    {
      label: "Comptes",
      href: "/accounts",
      icon: LuFolderClosed,
    },
    {
      label: "Utilisateurs",
      href: "/users",
      icon: LuUsers,
    },
  ],
  customer: [
    {
      label: "Accueil",
      href: "/",
      icon: LuStore,
    },
    {
      label: "Planning",
      href: "/planning",
      icon: LuCalendarRange,
    },
  ],
  default: [],
};

export default pages;
