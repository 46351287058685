import { useEffect } from "react";
import { postQueryParams, postSelector } from "@/store/post";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";

export const useFetchPlanning = (
  id: string,
  status?: Exclude<Post["status"], "not-started">[],
  date?: string
) => {
  const [query, setQuery] = useRecoilState(postQueryParams);
  const posts = useRecoilValue(postSelector(query));

  useEffect(() => {
    setQuery((query) => ({ ...query, status, id }));
  }, [id, setQuery, status]);

  return posts;
};

export const useRefreshPlanning = () => {
  const query = useRecoilValue(postQueryParams);
  return useRecoilRefresher_UNSTABLE(postSelector(query));
};
