import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import ReportingViewHeader from "./ReportingViewHeader";
import { accountSelector } from "@/store/accounts";
import { useRecoilValue, useSetRecoilState } from "recoil";
import RepotingTab, { Tab } from "@/components/features/reports/ReportingTab";
import ReportingView from "./ReportingView";
import { Icon, IconProps } from "@repleek/mui";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import {
  insightsByType,
  reportingByRangeQuery,
  reportingListSelector,
  reportingSelector,
} from "@/store/reporting";
import NotFound from "@/components/layout/NotFound";
import Home from "@mui/icons-material/Home";
import { BusinessCenter, PlaylistAddCheck } from "@mui/icons-material";
import BuildingDashboard from "@/components/layout/BuildingDashboard";
import { useSocialAccess } from "@/hooks/account";
import { IoBarChartOutline } from "react-icons/io5";
import { SiFacebook, SiLinkedin, SiTiktok } from "react-icons/si";
import { SlSocialInstagram } from "react-icons/sl";
import { useBreadCrumb } from "@/hooks/layout";
import { authState } from "@/store/auth";
import ReportingSelect from "./ReportingSelect";
import { FaCaretDown } from "react-icons/fa";
import { SocialReport } from "@/modules/strapi-sdk/lib/interfaces";
import LeadsReporting from "./LeadsReporting";

type Props = {
  accountId: string;
  id?: string;
};

const ReportingComponent: React.FC<Props> = ({ accountId, id }) => {
  const account = useRecoilValue(accountSelector(accountId));
  const [reportId, setReportId] = useState(id);
  const reporting = useRecoilValue(
    reportingSelector({ accountId, id: reportId })
  );
  const reportings = useRecoilValue(reportingListSelector(accountId));
  const user = useRecoilValue(authState);
  const setRepotingRange = useSetRecoilState(reportingByRangeQuery(accountId));
  const data = useRecoilValue(insightsByType({ type: "global", accountId }));
  const access = useSocialAccess(account?.configuration);

  const defaultValues = useMemo(
    () => ({
      from: dayjs(),
      to: dayjs(),
    }),
    []
  );

  const tabs = useMemo<Tab[]>(() => {
    const res = [
      {
        id: "global",
        label: <Label label="Global" type="global" />,
        content: (
          <ReportingView type={"global"} accountId={accountId.toString()} />
        ),
      },
    ];

    if (access.facebook)
      res.push({
        id: "facebook",
        label: <Label label="Facebook" type="facebook" />,
        content: (
          <ReportingView type={"facebook"} accountId={accountId.toString()} />
        ),
      });

    if (access.instagram)
      res.push({
        id: "instagram",
        label: <Label label="Instagram" type="instagram" />,
        content: (
          <ReportingView type={"instagram"} accountId={accountId.toString()} />
        ),
      });

    if (access.tiktok)
      res.push({
        id: "tiktok",
        label: <Label label="TikTok" type="tiktok" />,
        content: (
          <ReportingView type={"tiktok"} accountId={accountId.toString()} />
        ),
      });

    if (access.linkedin)
      res.push({
        id: "linkedIn",
        label: <Label label="LinkedIn" type="linkedIn" />,
        content: (
          <ReportingView type={"linkedin"} accountId={accountId.toString()} />
        ),
      });

    return res;
  }, [
    access.facebook,
    access.instagram,
    access.linkedin,
    access.tiktok,
    accountId,
  ]);

  useBreadCrumb(
    user?.type === "admin"
      ? [
          { label: "Comptes", icon: Home, href: "/accounts" },
          {
            label: account?.name || "Compte",
            icon: BusinessCenter,
            href: `/account/${accountId}`,
          },
          { label: reporting?.name || "Reporting", icon: PlaylistAddCheck },
        ]
      : []
  );

  useEffect(() => {
    if (reporting?.date) {
      const date = dayjs(reporting.date);
      setRepotingRange({
        from: date.subtract(2, "M").toDate(),
        to: date.toDate(),
      });
    }
  }, [defaultValues, id, reporting, reportings?.data, setRepotingRange]);

  useEffect(() => {
    if (!id) {
      setReportId(reportings?.data?.[0]?.id?.toString());
    }
  }, [id, reportings?.data]);

  if (id && !reporting?.id) return <NotFound />;

  return (
    <>
      <ReportingViewHeader
        logo={{
          src: account?.url || "/images/logo-pistache.jpg",
        }}
        name={{ text: account?.name }}
        email={{ text: account?.email }}
        action={
          user?.type !== "customer"
            ? {
                type: "button",
                href: `/${accountId}/edit-reporting/${id}`,
              }
            : {
                hidden: true,
              }
        }
        form={{}}
      />

      {data ? (
        <>
          {user?.type === "customer" && (
            <ReportingSelect
              name={{
                text: reporting?.name,
              }}
              select={{
                children: (
                  <SelectReport
                    options={reportings?.data || []}
                    onChange={setReportId}
                    value={reportId}
                  />
                ),
              }}
            />
          )}
          <RepotingTab tabs={tabs} defaultTab={"global"} />
        </>
      ) : (
        <BuildingDashboard />
      )}

      {reporting?.id && account?.sheet && <LeadsReporting id={reporting.id} />}
    </>
  );
};

export default ReportingComponent;

const Label: React.FC<{ label: string; type: string }> = (props) => {
  const { label, type } = props;
  const oauth: Record<string, IconProps> = useMemo(
    () => ({
      global: {
        icon: IoBarChartOutline,
        style: { color: "#FF4DF7" },
      },
      tiktok: {
        icon: SiTiktok,
        style: { color: "#000000" },
      },
      facebook: {
        icon: SiFacebook,
        style: { color: "#1877F2" },
      },
      instagram: {
        icon: SlSocialInstagram,
        style: { color: "#833AB4" },
      },
      linkedIn: {
        icon: SiLinkedin,
        style: { color: "#0A66C2" },
      },
    }),
    []
  );

  const icon = useMemo(() => oauth[type], [oauth, type]);

  return (
    <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <Icon {...icon} css={{ fontSize: "18px" }} />
      <Typography variant="h3" sx={{ fontSize: "14px" }}>
        {label}
      </Typography>
    </Box>
  );
};

type SelectReportProps = {
  options: SocialReport[];
  onChange: (option: string) => void;
  value?: string;
};

const SelectReport: React.FC<SelectReportProps> = (props) => {
  const { options, value, onChange } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option?: string) => {
    setAnchorEl(null);
    setTimeout(() => {
      if (option) onChange(option);
    }, 300);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <FaCaretDown />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        {options.map(({ id, name }) => (
          <MenuItem
            onClick={() => handleClose(id?.toString())}
            key={id}
            disabled={value === id?.toString()}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
