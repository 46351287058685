import React, { useCallback } from "react";
import { WkSsoCard } from "@/components";
import OAuthPopup from "@/components/features/account/OAuthPopup";
import { JWTSession, MakeRequired } from "@/interfaces";
import { Workspace } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { workspaceById } from "@/store/workspace";

type Props = {
  workspace: MakeRequired<Workspace, "id">;
};

const Informations: React.FC<Props> = (props) => {
  const { workspace } = props;

  const [strapi] = useStrapi();
  const refreshWk = useRecoilRefresher_UNSTABLE(workspaceById(workspace.id));

  const linkGoogleManagerWorkspace = useCallback(
    async (session: JWTSession) => {
      if (session.user?.id) {
        const res = await strapi?.post(
          "workspaces/:workspaceId/link-social-account/:provider",
          {
            ":workspaceId": workspace.id,
            ":provider": "google",
          },
          {
            access_token: session.access_token,
            expires_in: session.expires_in,
            id: session.user.id,
            name: session.user?.name,
            picture: session.user?.picture,
            refresh_token: session.refresh_token,
            refresh_expires_in: session.refresh_expires_in,
          }
        );
        if (res) {
          // refresh workspace
          refreshWk();
        }
      }
    },
    [refreshWk, strapi, workspace.id]
  );

  const linkHootsuiteManagerWorkspace = useCallback(
    async (session: JWTSession) => {
      console.log("session", session);
    },
    []
  );

  const onUnlink = useCallback(async () => {
    await strapi?.update("workspaces", workspace.id, {
      gmb_account_manager: null,
    } as any);
    refreshWk();
  }, [refreshWk, strapi, workspace.id]);

  return (
    <>
      <WkSsoCard
        syncBtn={{
          children: !workspace.gmb_account_manager && (
            <OAuthPopup
              type="google"
              label="Se connecter"
              onSuccess={linkGoogleManagerWorkspace}
            />
          ),
        }}
        name={{
          text: "Google My Business",
        }}
        logo={{
          src: "/images/logo-google.svg",
        }}
        description={{
          text: "Pour publier des posts, photos ou vidéos sur votre fiche Google, vous devez d'abord connecter et autoriser votre compte Google My Business.",
        }}
        accountSynced={{
          hidden: !workspace.gmb_account_manager,
        }}
        accountAvatar={{
          src: workspace.gmb_account_manager?.picture,
        }}
        accountName={{
          text: workspace.gmb_account_manager?.name,
        }}
        unlinkBtn={{ onClick: onUnlink }}
      />
      <WkSsoCard
        syncBtn={{
          children: (
            <OAuthPopup
              type="hootsuite"
              label="Se connecter"
              onSuccess={linkHootsuiteManagerWorkspace}
            />
          ),
        }}
        name={{ text: "Hootsuite" }}
        logo={{
          src: "/images/logo-hootsuite.svg",
        }}
        description={{}}
        accountSynced={{}}
        accountAvatar={{}}
        accountName={{}}
        unlinkBtn={{}}
      />
    </>
  );
};

export default Informations;
