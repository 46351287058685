import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
} from "@minchat/react-chat-ui";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { messagesByPost } from "@/store/messages";
import MessageType from "@minchat/react-chat-ui/dist/types/MessageType";
import { useStrapi } from "@/providers/StrapiProvider";
import { authState } from "@/store/auth";

type Props = {
  postId: number;
  setReloadPlanning: (reload: boolean) => void;
};

const Comments: React.FC<Props> = (props) => {
  const { postId, setReloadPlanning } = props;
  const [strapi] = useStrapi();

  const userAuth = useRecoilValue(authState);
  const messages = useRecoilValue(messagesByPost({ id: postId }));
  const refreshMessages = useRecoilRefresher_UNSTABLE(
    messagesByPost({ id: postId })
  );

  const [messageList, setMessageList] = useState<MessageType[]>([]);
  const [botTyping, setBotTyping] = useState(false);

  const canReply = useMemo(
    () => !messageList.find(({ id }) => id === "THANK-YOU-MESSAGE"),
    [messageList]
  );
  const user = useMemo(
    () => ({
      id: userAuth?.id.toString() || "user",
      name: [userAuth?.firstName, userAuth?.lastName]
        .filter((v) => v)
        .join(" "),
    }),
    [userAuth]
  );
  const botMessages = useMemo(() => {
    const user = {
      id: "pistache-bot",
      name: "Pistache",
      avatar: "/images/logo-pistache.jpg",
    };
    return {
      "WELCOME-MESSAGE": {
        id: "WELCOME-MESSAGE",
        text: "Bonjour ! 😊 Avez-vous des commentaires à partager sur la publication réalisée ? Nous serions ravis de connaître vos retours.",
        user,
      },
      "NOTICE-MESSAGE": {
        id: "NOTICE-MESSAGE",
        text: "⚠️ Note : Vous avez la possibilité de laisser un seul commentaire, alors assurez-vous qu'il exprime tout ce que vous souhaitez partager !",
        user,
      },
      "THANK-YOU-MESSAGE": {
        id: "THANK-YOU-MESSAGE",
        text: "Merci beaucoup pour votre commentaire ! 🙏 L'équipe a bien pris en compte votre message et apprécie votre contribution.",
        user,
      },
    };
  }, []);

  const onBotAddMessage = useCallback(
    (message: MessageType, delay = 1_500) =>
      new Promise((resolve) => {
        setBotTyping(true);
        setTimeout(() => {
          setMessageList((list) =>
            !list.find(({ id }) => id === message.id)
              ? [...list, message]
              : list
          );
          setBotTyping(false);
          resolve(true);
        }, delay);
      }),
    []
  );

  const onUserAddMessage = useCallback(
    async (text: string) => {
      if (userAuth) {
        const response = await strapi?.create("messages", {
          text,
          author: userAuth,
          post: { id: postId },
          readBy: [userAuth.id],
          status: "open",
          notion_task: true,
        });

        if (response?.data?.id) {
          setMessageList((list) => [
            ...list,
            {
              id: response.data?.id?.toString(),
              text,
              user,
            },
          ]);
          setReloadPlanning(true);
          await onBotAddMessage(botMessages["THANK-YOU-MESSAGE"]);
        }
      }
    },
    [
      botMessages,
      onBotAddMessage,
      postId,
      setReloadPlanning,
      strapi,
      user,
      userAuth,
    ]
  );

  const onAddServerMessages = useCallback(
    (messages: MessageType[]) => {
      setMessageList((list) =>
        !list.length
          ? [
              botMessages["WELCOME-MESSAGE"],
              botMessages["NOTICE-MESSAGE"],
              ...messages,
              botMessages["THANK-YOU-MESSAGE"],
            ]
          : list
      );
    },
    [botMessages]
  );

  useEffect(() => {
    if (messageList.length) return;
    // Empty messages
    if (!messages.length) {
      // Welcome Message
      onBotAddMessage(botMessages["WELCOME-MESSAGE"]).then(() => {
        onBotAddMessage(botMessages["NOTICE-MESSAGE"]);
      });
    } else onAddServerMessages(messages);
  }, [
    botMessages,
    messageList.length,
    messages,
    messages.length,
    onAddServerMessages,
    onBotAddMessage,
    refreshMessages,
  ]);

  useEffect(() => {
    refreshMessages();
  }, [refreshMessages]);

  return (
    <MainContainer style={{ width: "calc(100% + 12px)", height: "100%" }}>
      <MessageContainer>
        <MessageList
          currentUserId={userAuth?.id.toString() || ""}
          messages={messageList}
          showTypingIndicator={botTyping}
        />
        {canReply && (
          <MessageInput
            placeholder="Tapez votre message ici"
            showSendButton={true}
            showAttachButton={false}
            disabled={botTyping}
            onSendMessage={onUserAddMessage}
          />
        )}
      </MessageContainer>
    </MainContainer>
  );
};

export default Comments;
