/** Build with repleek */
// #region Import
import React from "react";

import {
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Typography,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type MisConfigurationProps = Partial<ContainerProps> & {
  selecteGMB: ButtonProps & {},
};
// #endregion

// #region MisConfigurationComponent
const MisConfiguration: React.FC<MisConfigurationProps> = (props) => {
  const { selecteGMB, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{
        height: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Typography
        variant={"h3"}
        text={"Connectez votre compte Google My Business"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "22px", marginBottom: "8px" }}
      />
      <Typography
        variant={"body1"}
        text={
          "Pour publier des posts, des photos ou des vidéos sur votre fiche Google, vous devez d'abord connecter votre compte Google My Business. Cliquez sur le bouton ci-dessous pour commencer le processus de connexion et d'autorisation."
        }
        gutterBottom={false}
        noWrap={false}
      />
      <Button
        text={"Selectionner un compte"}
        type={"button"}
        disabled={false}
        disableElevation={false}
        disableFocusRipple={false}
        disableRipple={false}
        fullWidth={false}
        iconStart={false}
        iconEnd={false}
        css={{ marginTop: "18px" }}
        {...selecteGMB}
      />
    </Container>
  );
};

export default MisConfiguration;
// #endregion
