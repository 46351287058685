/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type UserAvatarProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
};
// #endregion

// #region UserAvatarComponent
const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  const { avatar, name, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        padding: "8px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        overflowY: "hidden",
        borderRadius: "12px",
        gap: "8px",
        display: "flex",
        alignItems: "center",
      }}
      {...rootProps}
    >
      <Avatar
        variant={"circular"}
        initial={"AA"}
        component={"div"}
        css={{
          width: "38px",
          height: "38px",
          fontFamily: "Unbounded",
          fontSize: "12px",
          color: "rgba(255,255,255, 1)",
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: "rgb(239, 239, 250)",
          background:
            "linear-gradient(90deg, rgb(151, 150, 240) 0%, rgb(251, 199, 212) 100%)",
        }}
        {...avatar}
      />
      <Typography
        variant={"h3"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "14px" }}
        {...name}
      />
    </Box>
  );
};

export default UserAvatar;
// #endregion
