import { atomFamily, selectorFamily } from "recoil";
import { strapiInstance } from "@/providers/StrapiProvider";
import { PaginationByPage } from "@/modules/strapi-sdk";
import { authState } from "./auth";
import { StrapiMetaResponse } from "@/modules/strapi-sdk/lib/types";
import dayjs from "dayjs";
import { Count, SocialReport } from "@/modules/strapi-sdk/lib/interfaces";
import { ReportingViewType } from "@/components/features/reports/ReportingView";
import { accountSelector } from "./accounts";
import { getSocialAccess } from "@/hooks/account";
import { typedEntries } from "@/utils";

type Params = {
  id?: string;
  accountId?: string;
};

type InsightsByTypeParams = {
  accountId: string;
  type: ReportingViewType;
};

type Insights = Record<
  ReportingViewType,
  {
    comment: string;
    insights: Record<
      | "followers"
      | "impressions"
      | "interactions"
      | "posts"
      | "engagement"
      | "reactions_likes"
      | "comments",
      {
        name: string;
        value: number;
        subValues: { name: string; value: number }[];
      }[]
    >;
  }
>;

export const reportingSelector = selectorFamily({
  key: "report-selector",
  get:
    ({ id, accountId }: Params) =>
    async () => {
      if (id && accountId) {
        const res = await strapiInstance.findOne("social-reports", id, {
          fields: ["name", "comment", "id", "date"],
          populate: ["facebook", "instagram", "tiktok", "linkedin", "account"],
        });

        if (res?.account?.id === parseInt(accountId)) {
          return res;
        }
        return undefined;
      }
    },
});

export const reportingQuery = atomFamily<
  { pagination: PaginationByPage },
  string
>({
  key: "reporting-query",
  default: {
    pagination: {
      page: 1,
      pageSize: 5,
      withCount: true,
    },
  },
});

export const reportingListSelector = selectorFamily({
  key: "reporting-list-selector",
  get:
    (id: string | undefined) =>
    async ({ get }) => {
      try {
        const user = get(authState);
        if (user && id) {
          const query = get(reportingQuery(id));
          return await strapiInstance.find("social-reports", {
            ...query,
            sort: "date:desc",
            filters: {
              account: {
                id: { $eq: id },
              },
            },
            fields: ["id", "name", "date", "lastView"],
          });
        }
      } catch (error) {
        console.log("error: ", error);
        return undefined;
      }
    },
});

export const reportingMeta = atomFamily<StrapiMetaResponse | undefined, string>(
  {
    key: "reporting-meta",
    default: undefined,
  }
);

export const reportingByRangeQuery = atomFamily<
  { from: Date; to: Date } | undefined,
  string
>({
  key: "reporting-by-range-query",
  default: undefined,
});

export const reportingByRange = selectorFamily({
  key: "reporting-by-range-selector",
  get:
    (accountId: string) =>
    async ({ get }) => {
      try {
        const range = get(reportingByRangeQuery(accountId));
        if (range) {
          const user = get(authState);
          const account = get(accountSelector(accountId));
          const from = dayjs(range.from).format("YYYY-MM-DD");
          const to = dayjs(range.to).format("YYYY-MM-DD");

          if (user && accountId) {
            const res = await strapiInstance.find("social-reports", {
              sort: "date:asc",
              filters: {
                account: {
                  id: { $eq: accountId },
                },
                date: {
                  $between: [from, to],
                },
              },
              fields: ["date", "comment"],
              populate: ["account", ...getSocialAccess(account?.configuration)],
            });

            if (res?.data) return getInsights(res.data);
          }
        }
        return undefined;
      } catch (error) {
        console.trace("error: ", error);
        return undefined;
      }
    },
});

export const insightsByType = selectorFamily({
  key: "insights-by-type",
  get:
    (params: InsightsByTypeParams) =>
    async ({ get }) => {
      const { type, accountId } = params;

      const insights = get(reportingByRange(accountId));
      if (insights) return insights[type];
      return undefined;
    },
});

const getInsights = (socialReport: SocialReport[]): Insights => {
  const defaultValues: Insights = {
    global: {
      comment: "",
      insights: {
        followers: [],
        impressions: [],
        interactions: [],
        posts: [],
        engagement: [],
        reactions_likes: [],
        comments: [],
      },
    },
    linkedin: {
      comment: "",
      insights: {
        followers: [],
        impressions: [],
        interactions: [],
        posts: [],
        engagement: [],
        reactions_likes: [],
        comments: [],
      },
    },
    tiktok: {
      comment: "",
      insights: {
        followers: [],
        impressions: [],
        interactions: [],
        posts: [],
        engagement: [],
        reactions_likes: [],
        comments: [],
      },
    },
    facebook: {
      comment: "",
      insights: {
        followers: [],
        impressions: [],
        interactions: [],
        posts: [],
        engagement: [],
        reactions_likes: [],
        comments: [],
      },
    },
    instagram: {
      comment: "",
      insights: {
        followers: [],
        impressions: [],
        interactions: [],
        posts: [],
        engagement: [],
        reactions_likes: [],
        comments: [],
      },
    },
  };

  socialReport.forEach(
    ({ date, comment, facebook, instagram, linkedin, tiktok }) => {
      if (date) {
        const name = dayjs(date).format("MMMM");
        const test = [facebook, instagram, linkedin, tiktok];

        defaultValues["global"]["comment"] = comment || "";
        defaultValues["global"]["insights"] = pushCountToInsights(
          defaultValues["global"],
          getGlobals(test as Count[]),
          name
        ).insights;

        if (facebook)
          defaultValues["facebook"] = pushCountToInsights(
            defaultValues["facebook"],
            facebook,
            name
          );

        if (instagram)
          defaultValues["instagram"] = pushCountToInsights(
            defaultValues["instagram"],
            instagram,
            name
          );

        if (linkedin)
          defaultValues["linkedin"] = pushCountToInsights(
            defaultValues["linkedin"],
            linkedin,
            name
          );

        if (tiktok)
          defaultValues["tiktok"] = pushCountToInsights(
            defaultValues["tiktok"],
            tiktok,
            name
          );
      }
    }
  );

  typedEntries(defaultValues).forEach(([type, data]) => {
    typedEntries(data.insights).forEach(([key, insights]) => {
      if (key === "comments" || key === "reactions_likes") {
        insights.forEach(({ name, value }) => {
          const elIndex = defaultValues[type].insights[
            "interactions"
          ].findIndex((el) => el.name === name);
          if (elIndex >= 0) {
            const { subValues, ...rest } =
              defaultValues[type].insights["interactions"][elIndex];
            defaultValues[type].insights["interactions"][elIndex] = {
              ...rest,
              subValues: [...subValues, { name: labels[key], value }],
            };
          }
        });
      }
    });
  });

  return defaultValues;
};

const pushCountToInsights = (
  insights: {
    comment: string;
    insights: Record<
      | "followers"
      | "impressions"
      | "interactions"
      | "posts"
      | "engagement"
      | "reactions_likes"
      | "comments",
      {
        name: string;
        value: number;
        subValues: { name: string; value: number }[];
      }[]
    >;
  },
  count: Count,
  name: string
) => {
  (
    Object.keys(count) as (keyof Omit<
      Count,
      "newPosts" | "newFollowers" | "newImpressions" | "newInteractions"
    >)[]
  ).forEach((key) => {
    if (
      key === "followers" ||
      key === "impressions" ||
      key === "interactions" ||
      key === "posts" ||
      key === "comments" ||
      key === "reactions_likes" ||
      key === "engagement"
    ) {
      insights["insights"][key] = [
        ...insights["insights"][key],
        { name, value: count[key] || 0, subValues: [] },
      ];
    }

    if (key === "comment") {
      insights["comment"] = count.comment || "";
    }
  });
  return insights;
};

const getGlobals = (counts: Count[]): Count => {
  let engagementCounter = 1;
  const values = counts.reduce(
    (acc, count) => {
      if (count) {
        const {
          followers,
          impressions,
          interactions,
          posts,
          engagement,
          reactions_likes,
          comments,
        } = count;
        if (acc["engagement"]) engagementCounter += 1;
        acc["followers"] = (acc["followers"] || 0) + (followers || 0);
        acc["impressions"] = (acc["impressions"] || 0) + (impressions || 0);
        acc["interactions"] = (acc["interactions"] || 0) + (interactions || 0);
        acc["posts"] = (acc["posts"] || 0) + (posts || 0);
        acc["engagement"] = (acc["engagement"] || 0) + (engagement || 0);
        acc["comments"] = (acc["comments"] || 0) + (comments || 0);
        acc["reactions_likes"] =
          (acc["reactions_likes"] || 0) + (reactions_likes || 0);
      }
      return acc;
    },
    {
      followers: 0,
      impressions: 0,
      interactions: 0,
      posts: 0,
      engagement: 0,
      comments: 0,
      reactions_likes: 0,
    }
  );

  values["engagement"] = (values["engagement"] || 0) / engagementCounter;
  return values;
};

const labels = {
  comments: "💬",
  reactions_likes: "❤️",
};
