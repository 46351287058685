import React from "react";
import { useParams } from "react-router-dom";

const Reviews: React.FC = () => {
  const { id } = useParams() as { id: string };

  return <div>Reviews</div>;
};

export default Reviews;
