/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { BiLike } from "react-icons/bi";
import { FaRegComment } from "react-icons/fa6";
import { IoIosPaperPlane } from "react-icons/io";
import { TbRefresh } from "react-icons/tb";
// #endregion

// #region Type Definition
type TiktokSkinProps = Partial<BoxProps> & {
  avatar: Partial<AvatarProps>,
  name: TypographyProps & {},
  description: TypographyProps & {},
  tags: Partial<BoxProps>,
  media: Partial<BoxProps>,
};
// #endregion

// #region TiktokSkinComponent
const TiktokSkin: React.FC<TiktokSkinProps> = (props) => {
  const { avatar, name, description, tags, media, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{ maxWidth: "330px", minHeight: "430px", position: "relative" }}
      {...rootProps}
    >
      <Box tag={"div"} {...media} />
      <Box
        tag={"div"}
        css={{
          color: "rgba(255,255,255, 1)",
          position: "absolute",
          bottom: "0px",
          left: "0px",
          right: "0px",
          background:
            "linear-gradient(180deg, RGBA(4, 4, 4, 0) 0%, rgba(1,1,1,1) 100%)",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Box
          tag={"div"}
          css={{
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "8px",
            paddingBottom: "8px",
            flexGrow: "1",
          }}
        >
          <Typography
            variant={"h4"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "16px", flexGrow: "1", marginBottom: "8px" }}
            {...name}
          />
          <Box
            tag={"div"}
            css={{ maxHeight: "80px", overflow: "hidden", overflowY: "auto" }}
          >
            <Typography
              variant={"body1"}
              text={"Mon titre ici"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "14px", lineHeight: "16px" }}
              {...description}
            />
            <Box
              tag={"div"}
              css={{
                fontSize: "14px",
                lineHeight: "14px",
                paddingTop: "8px",
                paddingBottom: "8px",
                gap: "8px",
                display: "flex",
                flexWrap: "wrap",
              }}
              {...tags}
            />
          </Box>
        </Box>
        <Box
          tag={"div"}
          css={{
            padding: "12px",
            maxWidth: "88px",
            gap: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Avatar
            variant={"circular"}
            component={"div"}
            css={{
              backgroundColor: "rgba(255,255,255, 1)",
              marginBottom: "8px",
            }}
            {...avatar}
          />
          <Box
            tag={"div"}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={BiLike}
              css={{ fontSize: "26px" }}
            />
            <Typography
              variant={"body1"}
              text={"25,4 K "}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "12px" }}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={FaRegComment}
              css={{ fontSize: "26px" }}
            />
            <Typography
              variant={"body1"}
              text={"1 465"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "12px" }}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={TbRefresh}
              css={{ fontSize: "26px" }}
            />
            <Typography
              variant={"body1"}
              text={"45,8 K"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "12px" }}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={IoIosPaperPlane}
              css={{ fontSize: "26px" }}
            />
            <Typography
              variant={"body1"}
              text={"89,2 K"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "12px" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TiktokSkin;
// #endregion
