/** Build with repleek */
// #region Import
import React from "react";

import {
  Autocomplete,
  AutocompleteProps,
  Box,
  BoxProps,
  ButtonIcon,
  ButtonIconProps,
  Chip,
  Divider,
  Icon,
  SelectField,
  SelectFieldProps,
  Typography,
} from "@repleek/mui";
import { RiRefreshLine } from "react-icons/ri";
// #endregion

// #region Type Definition
type SheetConnexionFieldsProps = Partial<BoxProps> & {
  mapperBox: Partial<BoxProps>,
  sheetSelect: Partial<AutocompleteProps>,
  citySelect: Partial<SelectFieldProps>,
  nameSelect: Partial<SelectFieldProps>,
  cityBox: Partial<BoxProps>,
  phoneSelect: Partial<SelectFieldProps>,
  emailSelect: Partial<SelectFieldProps>,
  refreshSheet: Partial<ButtonIconProps>,
  idSelect: Partial<SelectFieldProps>,
  dateSelect: Partial<SelectFieldProps>,
};
// #endregion

// #region SheetConnexionFieldsComponent
const SheetConnexionFields: React.FC<SheetConnexionFieldsProps> = (props) => {
  const {
    mapperBox,
    sheetSelect,
    citySelect,
    nameSelect,
    cityBox,
    phoneSelect,
    emailSelect,
    refreshSheet,
    idSelect,
    dateSelect,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        maxWidth: "600px",
        paddingLeft: "8px",
        paddingRight: "8px",
        paddingTop: "16px",
        paddingBottom: "8px",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{ gap: "8px", display: "flex", alignItems: "center" }}
      >
        <Autocomplete
          inputName={"sheet"}
          label={"Nom du Sheet"}
          variant={"outlined"}
          color={"primary"}
          aria-label={"Input Base Mui"}
          clearText={"Vider"}
          closeText={"Fermer"}
          loadingText={"Chargement ..."}
          noOptionsText={"No option"}
          size={"small"}
          disabled={false}
          required={false}
          autoComplete={false}
          autoHighlight={false}
          autoSelect={false}
          blurOnSelect={false}
          clearOnBlur={false}
          clearOnEscape={false}
          fullWidth={true}
          disableClearable={false}
          disableCloseOnSelect={false}
          disabledItemsFocusable={false}
          disableListWrap={false}
          disablePortal={false}
          filterSelectedOptions={true}
          freeSolo={false}
          multiple={false}
          chipsColor={"default"}
          chipsSize={"medium"}
          chipsVariant={"filled"}
          {...sheetSelect}
        />
        <ButtonIcon
          type={"button"}
          disabled={false}
          disableFocusRipple={false}
          disableRipple={false}
          Icon={
            <Icon color={"inherit"} fontSize={"inherit"} icon={RiRefreshLine} />
          }
          {...refreshSheet}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          marginTop: "8px",
          borderRadius: "4px",
          paddingTop: "8px",
        }}
        {...mapperBox}
      >
        <Divider
          chip={true}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"left"}
          variant={"fullWidth"}
          Chip={
            <Chip
              label={"Champs"}
              avatar={false}
              icon={false}
              clickable={false}
              color={"default"}
              variant={"filled"}
            />
          }
        />
        <Box tag={"div"} css={{ padding: "8px" }}>
          <Box
            tag={"div"}
            css={{
              paddingLeft: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"overline"}
              text={"Identifiant du lead"}
              gutterBottom={false}
              noWrap={false}
              css={{ flexGrow: "1" }}
            />
            <SelectField
              options={[{ label: "Default", value: "default" }]}
              inputName={"keyMapper.externalId"}
              label={"ID"}
              variant={"outlined"}
              color={"primary"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              multiple={false}
              readOnly={false}
              required={true}
              size={"small"}
              css={{ maxWidth: "180px" }}
              {...idSelect}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              paddingLeft: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"overline"}
              text={"Nom du lead"}
              gutterBottom={false}
              noWrap={false}
              css={{ flexGrow: "1" }}
            />
            <SelectField
              options={[{ label: "Default", value: "default" }]}
              inputName={"keyMapper.name"}
              label={"Nom"}
              variant={"outlined"}
              color={"primary"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              multiple={false}
              readOnly={false}
              required={true}
              size={"small"}
              css={{ maxWidth: "180px" }}
              {...nameSelect}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              paddingLeft: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"overline"}
              text={"Date"}
              gutterBottom={false}
              noWrap={false}
              css={{ flexGrow: "1" }}
            />
            <SelectField
              options={[{ label: "Default", value: "default" }]}
              inputName={"keyMapper.date"}
              label={"Date"}
              variant={"outlined"}
              color={"primary"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              multiple={false}
              readOnly={false}
              required={true}
              size={"small"}
              css={{ maxWidth: "180px" }}
              {...dateSelect}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              paddingLeft: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"overline"}
              text={"Téléphone du lead"}
              gutterBottom={false}
              noWrap={false}
              css={{ flexGrow: "1" }}
            />
            <SelectField
              options={[{ label: "Default", value: "default" }]}
              inputName={"keyMapper.phone"}
              label={"Téléphone"}
              variant={"outlined"}
              color={"primary"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              multiple={false}
              readOnly={false}
              required={true}
              size={"small"}
              css={{ maxWidth: "180px" }}
              {...phoneSelect}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              paddingLeft: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant={"overline"}
              text={"Email du lead"}
              gutterBottom={false}
              noWrap={false}
              css={{ flexGrow: "1" }}
            />
            <SelectField
              options={[{ label: "Default", value: "default" }]}
              inputName={"keyMapper.email"}
              label={"Email"}
              variant={"outlined"}
              color={"primary"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              multiple={false}
              readOnly={false}
              required={true}
              size={"small"}
              css={{ maxWidth: "180px" }}
              {...emailSelect}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              paddingLeft: "8px",
              marginBottom: "8px",
              display: "flex",
              alignItems: "center",
            }}
            {...cityBox}
          >
            <Typography
              variant={"overline"}
              text={"Ville"}
              gutterBottom={false}
              noWrap={false}
              css={{ flexGrow: "1" }}
            />
            <SelectField
              options={[{ label: "Default", value: "default" }]}
              inputName={"keyMapper.city"}
              label={"Ville"}
              variant={"outlined"}
              color={"primary"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              multiple={false}
              readOnly={false}
              required={false}
              size={"small"}
              css={{ maxWidth: "180px" }}
              {...citySelect}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SheetConnexionFields;
// #endregion
