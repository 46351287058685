import React, { Suspense, useCallback, useEffect, useMemo } from "react";
import { Modal, PistacheLoader } from "@/components/layout";
import { SheetConnexionFields } from "@/components";
import { useFormContext, useWatch } from "react-hook-form";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { sheetList } from "@/store/leads";
import { useStrapi } from "@/providers/StrapiProvider";
import { accountSelector } from "@/store/accounts";

type Props = { open: boolean; onClose: () => void; accountId: string };
interface KeyMapper {
  name: string;
  phone: string;
  email: string;
  city: string;
}
interface Sheet {
  label: string;
  value: string;
  id: number;
}

const SheetModal: React.FC<Props> = (props) => {
  const { accountId, open, onClose } = props;
  const [strapi, { loading }] = useStrapi();

  const refreshAccount = useRecoilRefresher_UNSTABLE(
    accountSelector(accountId)
  );
  const refreshSheetList = useRecoilRefresher_UNSTABLE(sheetList);

  const onSubmit = useCallback(
    async ({ keyMapper, sheet }: { sheet: Sheet; keyMapper: KeyMapper }) => {
      if (sheet?.id) {
        // update keyMapper
        await strapi?.update("sheets", sheet.id, { keyMapper });
        // attach sheet to account
        await strapi?.update("accounts", accountId, {
          sheet: { id: sheet.id },
        });
        // sync leads from google
        await strapi?.get("leads/google-sheet/:sheetId", {
          ":sheetId": sheet.value,
        });

        // refresh account
        refreshAccount();
        refreshSheetList();
        onClose();
      }
    },
    [accountId, onClose, refreshAccount, refreshSheetList, strapi]
  );

  return (
    <Modal
      type="form"
      open={open}
      onClose={onClose}
      title="Sélectionnez Google Sheets"
      loading={loading}
      form={{
        defaultValues: {
          sheet: "",
          keyMapper: {
            externalId: "",
            name: "",
            date: "",
            phone: "",
            email: "",
            city: "",
          },
        },
        onSubmit,
      }}
      onConfirm={() => {}}>
      <Suspense fallback={<PistacheLoader />}>
        <Form />
      </Suspense>
    </Modal>
  );
};

type MapperKey = "name" | "phone" | "email" | "city" | "externalId" | "date";

const Form: React.FC = () => {
  const sheet = useWatch({ name: "sheet" }) as { value: string; label: string };
  const keyMapper = useWatch({ name: "keyMapper" }) as Record<
    MapperKey,
    string | null
  >;
  const { resetField } = useFormContext();

  const [strapi, { loading }] = useStrapi();
  const sheets = useRecoilValue(sheetList);
  const refreshSheetList = useRecoilRefresher_UNSTABLE(sheetList);

  const options = useMemo(() => {
    const defaultOptions: Record<
      MapperKey,
      { label: string; value: string }[]
    > = {
      externalId: [],
      name: [],
      date: [],
      phone: [],
      email: [],
      city: [],
    };
    if (sheet) {
      const fields = (sheets.find(({ sheetId }) => sheetId === sheet.value)
        ?.fields || []) as string[];

      const options = Object.entries(keyMapper).reduce((acc, [key, value]) => {
        acc[key as MapperKey] = fields
          .filter(
            (option) =>
              !Object.values(keyMapper).includes(option) || value === option
          )
          .map((option) => ({
            label: option,
            value: option,
          }));

        return acc;
      }, defaultOptions);

      []
        .filter((option) => !Object.values(keyMapper).includes(option))
        .map((option) => ({
          label: option,
          value: option,
        }));

      return options;
    }
    return defaultOptions;
  }, [keyMapper, sheet, sheets]);

  const onRefreshSheet = useCallback(async () => {
    await strapi?.get("sheets/list");
    // refresh sheetList
    refreshSheetList();
  }, [refreshSheetList, strapi]);

  useEffect(() => {
    resetField("keyMapper");
  }, [resetField, sheet]);

  return (
    <SheetConnexionFields
      sheetSelect={{
        options: sheets?.map(({ name, sheetId, id }) => ({
          label: name,
          value: sheetId,
          id,
        })),
      }}
      refreshSheet={{ onClick: onRefreshSheet, disabled: loading }}
      mapperBox={{ hidden: !sheet }}
      idSelect={{ options: options.externalId }}
      nameSelect={{ options: options.name }}
      phoneSelect={{ options: options.phone }}
      emailSelect={{ options: options.email }}
      citySelect={{ options: options.city }}
      dateSelect={{ options: options.date }}
      cityBox={{}}
    />
  );
};

export default SheetModal;
