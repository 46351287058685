import React, { useCallback, useMemo } from "react";
import { CategoryLeadCard } from "@/components";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  leadInsightQuery,
  LeadsParams,
  leadsParamsQuery,
  leadStatus,
} from "@/store/leads";
import { Box, Button } from "@mui/material";

type Props = {
  id: number;
};

const Categories: React.FC<Props> = (props) => {
  const { id } = props;
  const insights = useRecoilValue(leadInsightQuery(id));
  const [{ category: currentCategory, status: currentStatus }, setParams] =
    useRecoilState(leadsParamsQuery(id));

  const categoriesCount = useMemo(() => {
    let counter: {
      label: string;
      count: string;
      category: string;
    }[] = [];

    if (insights?.categories) {
      if (Object.keys(insights).length === 1)
        counter = [
          {
            category: "ALL",
            label: "Tous",
            count: insights.categories["ALL"].toString(),
          },
        ];
      else
        counter = Object.entries(insights.categories).map(([category, value]) =>
          category === "ALL"
            ? {
                label: "Tous",
                count: value.toString(),
                category,
              }
            : {
                label: category,
                count: value.toString(),
                category,
              }
        );
    }

    return counter;
  }, [insights]);

  const statusCount = useMemo(() => {
    let counter: {
      label: string;
      count: number;
      status: string;
    }[] = [];

    if (insights?.status?.[currentCategory]) {
      const stats = insights.status[currentCategory];

      counter = Object.entries(stats).map(([status, count]) => ({
        label: leadStatus[status],
        count,
        status,
      }));
    }

    return counter;
  }, [currentCategory, insights?.status]);

  const onChange = useCallback(
    (params: Partial<LeadsParams>) => {
      setParams((prev) => ({ ...prev, ...params }));
    },
    [setParams]
  );

  return (
    <>
      <Box
        display={"flex"}
        gap={"8px"}
        sx={{ overflowX: "auto", py: 1.5, px: 1 }}>
        {categoriesCount.map(({ label, count, category }) => (
          <CategoryLeadCard
            className={currentCategory === category ? "selected" : ""}
            category={{ text: label }}
            count={{ text: count }}
            key={label}
            onClick={() => onChange({ category })}
          />
        ))}
      </Box>
      <Box
        sx={{ width: "100%", display: "flex", gap: "6px", flexWrap: "wrap" }}>
        {statusCount.map(({ count, label, status }) => (
          <Button
            key={status}
            sx={{
              boxShadow: "none",
              padding: "4px 4px 4px 8px",
              fontSize: "10px",
              textWrap: "nowrap",
            }}
            variant={currentStatus === status ? "contained" : "outlined"}
            onClick={() =>
              onChange({ status: currentStatus === status ? null : status })
            }>
            {label}
            <Box
              component="span"
              sx={{
                ml: 1,
                border: "1px solid black",
                padding: "0 6px",
                borderRadius: "16px",
                background: "black",
                color: "white",
              }}>
              {count}
            </Box>
          </Button>
        ))}
      </Box>
    </>
  );
};

export default Categories;
