/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Checkbox,
  Chip,
  Container,
  ContainerProps,
  Divider,
  SelectField,
  SelectFieldProps,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type EmailFormProps = Partial<ContainerProps> & {
  email: Partial<TextFieldProps>,
  back: ButtonProps & {},
  defaultForm: Partial<BoxProps>,
  rsPublish: Partial<BoxProps>,
  unsubscribe: Partial<BoxProps>,
  accessRequest: Partial<BoxProps>,
  commercialSelect: Partial<SelectFieldProps>,
  name: TypographyProps & {},
  unsubscribeDate: Partial<BoxProps>,
  cmSelect: Partial<SelectFieldProps>,
  firstName: Partial<BoxProps>,
};
// #endregion

// #region EmailFormComponent
const EmailForm: React.FC<EmailFormProps> = (props) => {
  const {
    email,
    back,
    defaultForm,
    rsPublish,
    unsubscribe,
    accessRequest,
    commercialSelect,
    name,
    unsubscribeDate,
    cmSelect,
    firstName,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      {...rootProps}
    >
      <Typography
        variant={"h3"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "18px", marginBottom: "16px" }}
        {...name}
      />
      <TextField
        inputName={"email"}
        label={"Destinataire"}
        variant={"outlined"}
        color={"primary"}
        type={"email"}
        aria-label={"Input Base Mui"}
        autoFocus={false}
        disabled={false}
        fullWidth={true}
        maxRows={1}
        minRows={1}
        rows={1}
        multiline={false}
        readOnly={false}
        required={true}
        size={"small"}
        css={{ marginBottom: "12px" }}
        {...email}
      />
      <Box tag={"div"} {...defaultForm}>
        <TextField
          inputName={"subject"}
          label={"Objet"}
          variant={"outlined"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={true}
          size={"small"}
          css={{ marginBottom: "12px" }}
        />
        <TextField
          inputName={"title"}
          label={"Titre"}
          variant={"outlined"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={false}
          size={"small"}
          css={{ marginBottom: "12px" }}
        />
        <TextField
          inputName={"message"}
          label={"Message"}
          variant={"outlined"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={"5"}
          minRows={"3"}
          rows={"3"}
          multiline={true}
          readOnly={false}
          required={false}
          size={"small"}
          css={{ marginBottom: "12px" }}
        />
        <Box tag={"div"} css={{ gap: "8px", display: "flex" }}>
          <TextField
            inputName={"cta.label"}
            label={"Ancre"}
            variant={"outlined"}
            color={"secondary"}
            type={"text"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={true}
            size={"small"}
            css={{ width: "280px", marginBottom: "12px" }}
          />
          <TextField
            inputName={"cta.link"}
            label={"Lien"}
            variant={"outlined"}
            color={"secondary"}
            type={"text"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={true}
            size={"small"}
            css={{ marginBottom: "12px" }}
          />
        </Box>
      </Box>
      <Box tag={"div"} {...firstName}>
        <TextField
          inputName={"firstName"}
          label={"Nom du destinataire"}
          variant={"outlined"}
          color={"primary"}
          type={"text"}
          aria-label={"Input Base Mui"}
          autoFocus={false}
          disabled={false}
          fullWidth={true}
          maxRows={1}
          minRows={1}
          rows={1}
          multiline={false}
          readOnly={false}
          required={true}
          size={"small"}
          css={{ marginBottom: "12px" }}
        />
      </Box>
      <Box tag={"div"} {...rsPublish}>
        <Box
          tag={"div"}
          css={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            paddingTop: "4px",
          }}
        >
          <Divider
            label={"Réseaux sociaux"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{ marginTop: "8px" }}
          />
          <Box tag={"div"} css={{ padding: "8px" }}>
            <Box
              tag={"div"}
              css={{
                boxShadow:
                  "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                paddingTop: "4px",
                marginBottom: "8px",
              }}
            >
              <Divider
                label={"Facebook & LinkedIn"}
                chip={false}
                flexItem={false}
                light={false}
                orientation={"horizontal"}
                textAlign={"left"}
                variant={"fullWidth"}
                css={{ fontSize: "14px", marginTop: "8px" }}
              />
              <Box tag={"div"} css={{ padding: "8px" }}>
                <Checkbox
                  inputName={"access.facebook"}
                  label={"Facebook"}
                  color={"primary"}
                  isChecked={false}
                  disabled={false}
                  disableTypography={false}
                  aria-label={"Input Base Mui"}
                  size={"small"}
                />
                <Checkbox
                  inputName={"access.linkedin"}
                  label={"LinkedIn"}
                  color={"primary"}
                  isChecked={false}
                  disabled={false}
                  disableTypography={false}
                  aria-label={"Input Base Mui"}
                  size={"small"}
                />
              </Box>
              <Divider
                label={"Instagram"}
                chip={false}
                flexItem={false}
                light={false}
                orientation={"horizontal"}
                textAlign={"left"}
                variant={"fullWidth"}
                css={{ fontSize: "14px", marginTop: "8px" }}
              />
              <Box
                tag={"div"}
                css={{ padding: "8px", gap: "8px", display: "flex" }}
              >
                <TextField
                  inputName={"access.instagram.username"}
                  label={"Nom d'utilisateur"}
                  variant={"outlined"}
                  color={"primary"}
                  type={"text"}
                  aria-label={"Input Base Mui"}
                  autoFocus={false}
                  disabled={false}
                  fullWidth={true}
                  maxRows={1}
                  minRows={1}
                  rows={1}
                  multiline={false}
                  readOnly={false}
                  required={false}
                  size={"small"}
                />
                <TextField
                  inputName={"access.instagram.password"}
                  label={"Mot de passe"}
                  variant={"outlined"}
                  color={"primary"}
                  type={"text"}
                  aria-label={"Input Base Mui"}
                  autoFocus={false}
                  disabled={false}
                  fullWidth={true}
                  maxRows={1}
                  minRows={1}
                  rows={1}
                  multiline={false}
                  readOnly={false}
                  required={false}
                  size={"small"}
                />
              </Box>
              <Divider
                label={"TikTok"}
                chip={false}
                flexItem={false}
                light={false}
                orientation={"horizontal"}
                textAlign={"left"}
                variant={"fullWidth"}
                css={{ fontSize: "14px", marginTop: "8px" }}
              />
              <Box
                tag={"div"}
                css={{ padding: "8px", gap: "8px", display: "flex" }}
              >
                <TextField
                  inputName={"access.tiktok.username"}
                  label={"Identifiant"}
                  variant={"outlined"}
                  color={"primary"}
                  type={"text"}
                  aria-label={"Input Base Mui"}
                  autoFocus={false}
                  disabled={false}
                  fullWidth={true}
                  maxRows={1}
                  minRows={1}
                  rows={1}
                  multiline={false}
                  readOnly={false}
                  required={false}
                  size={"small"}
                />
                <TextField
                  inputName={"access.tiktok.password"}
                  label={"Mot de passe"}
                  variant={"outlined"}
                  color={"primary"}
                  type={"text"}
                  aria-label={"Input Base Mui"}
                  autoFocus={false}
                  disabled={false}
                  fullWidth={true}
                  maxRows={1}
                  minRows={1}
                  rows={1}
                  multiline={false}
                  readOnly={false}
                  required={false}
                  size={"small"}
                />
              </Box>
            </Box>
          </Box>
          <Divider
            label={"Stratégie"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{ marginTop: "8px" }}
          />
          <Box
            tag={"div"}
            css={{ padding: "8px", gap: "8px", display: "flex" }}
          >
            <TextField
              inputName={"strategy.photos"}
              label={"Photos/visuels"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"small"}
            />
            <TextField
              inputName={"strategy.video"}
              label={"Vidéo"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={false}
              size={"small"}
            />
          </Box>
          <Box
            tag={"div"}
            css={{ padding: "8px", gap: "8px", display: "flex" }}
          >
            <TextField
              inputName={"strategy.planning_day"}
              label={"Pub. planning (jour)"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"small"}
            />
            <TextField
              inputName={"strategy.insight_day"}
              label={"Pub. C.R. (jour)"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"small"}
            />
          </Box>
          <Divider
            label={"Génération de leads"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{ marginTop: "8px" }}
          />
          <Box tag={"div"} css={{ padding: "8px" }}>
            <TextField
              inputName={"leads.email"}
              label={"Adresse mail du professionnel"}
              variant={"outlined"}
              color={"primary"}
              type={"email"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={false}
              size={"small"}
            />
          </Box>
          <Divider
            label={"Objectif visibilité"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{ marginTop: "8px" }}
          />
          <Box tag={"div"} css={{ padding: "8px" }}>
            <Checkbox
              inputName={"visibility.facebook"}
              label={"Facebook"}
              color={"primary"}
              isChecked={false}
              disabled={false}
              disableTypography={false}
              aria-label={"Input Base Mui"}
              size={"small"}
            />
            <Checkbox
              inputName={"visibility.instagram"}
              label={"Instagram"}
              color={"primary"}
              isChecked={false}
              disabled={false}
              disableTypography={false}
              aria-label={"Input Base Mui"}
              size={"small"}
            />
            <Checkbox
              inputName={"visibility.tiktok"}
              label={"TikTok"}
              color={"primary"}
              isChecked={false}
              disabled={false}
              disableTypography={false}
              aria-label={"Input Base Mui"}
              size={"small"}
            />
          </Box>
          <Divider
            label={"Facturation"}
            chip={false}
            flexItem={false}
            light={false}
            orientation={"horizontal"}
            textAlign={"left"}
            variant={"fullWidth"}
            css={{ marginTop: "8px" }}
          />
          <Box
            tag={"div"}
            css={{ padding: "8px", gap: "8px", display: "flex" }}
          >
            <TextField
              inputName={"facturation.registration_fee"}
              label={"Frais à la signature"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"small"}
            />
            <TextField
              inputName={"facturation.subscription_fee"}
              label={"Abonnement"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"small"}
            />
            <TextField
              inputName={"facturation.subscription_day"}
              label={"Jour du Prélèvement"}
              variant={"outlined"}
              color={"primary"}
              type={"number"}
              aria-label={"Input Base Mui"}
              autoFocus={false}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={true}
              size={"small"}
            />
          </Box>
        </Box>
      </Box>
      <Box
        tag={"div"}
        css={{
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          gap: "12px",
          marginTop: "8px",
          marginBottom: "8px",
          paddingTop: "8px",
        }}
        {...unsubscribe}
      >
        <Divider
          label={"Réseaux sociaux"}
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"left"}
          variant={"fullWidth"}
          css={{ marginTop: "8px" }}
        />
        <Box tag={"div"} css={{ padding: "8px" }}>
          <Checkbox
            inputName={"socials.facebook"}
            label={"Facebook"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
          <Checkbox
            inputName={"socials.instagram"}
            label={"Instagram"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
          <Checkbox
            inputName={"socials.linkedin"}
            label={"LinkedIn"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
          <Checkbox
            inputName={"socials.tiktok"}
            label={"TikTok"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
        </Box>
        <Divider
          label={"Dates"}
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"left"}
          variant={"fullWidth"}
          css={{ marginTop: "8px" }}
          Chip={
            <Chip
              label={"Chip label"}
              avatar={false}
              icon={false}
              clickable={false}
              color={"default"}
              variant={"filled"}
            />
          }
        />
        <Box
          tag={"div"}
          css={{
            gap: "12px",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            display: "flex",
            flexWrap: "wrap",
          }}
          {...unsubscribeDate}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          paddingTop: "4px",
          marginTop: "8px",
          marginBottom: "8px",
        }}
        {...accessRequest}
      >
        <Divider
          label={"Réseaux sociaux"}
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"left"}
          variant={"fullWidth"}
          css={{ marginTop: "8px" }}
        />
        <Box
          tag={"div"}
          css={{ padding: "8px", display: "flex", flexWrap: "wrap" }}
        >
          <Checkbox
            inputName={"facebook"}
            label={"Facebook"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
          <Checkbox
            inputName={"instagram"}
            label={"Instagram & TikTok"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
          <Checkbox
            inputName={"linkedin"}
            label={"LinkedIn"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
          <Checkbox
            inputName={"gmb"}
            label={"Google My Business"}
            color={"primary"}
            isChecked={false}
            disabled={false}
            disableTypography={false}
            aria-label={"Input Base Mui"}
            size={"small"}
          />
        </Box>
      </Box>
      <Box
        tag={"div"}
        css={{
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          paddingTop: "4px",
          marginTop: "16px",
          marginBottom: "8px",
        }}
      >
        <Divider
          label={"Contact"}
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"left"}
          variant={"fullWidth"}
          css={{ marginTop: "8px" }}
          Chip={
            <Chip
              label={"Chip label"}
              avatar={false}
              icon={false}
              clickable={false}
              color={"default"}
              variant={"filled"}
            />
          }
        />
        <Box tag={"div"} css={{ padding: "8px" }}>
          <SelectField
            options={[{ label: "Default", value: "default" }]}
            inputName={"cm"}
            label={"CM à contacter"}
            variant={"outlined"}
            color={"primary"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            multiple={false}
            readOnly={false}
            required={true}
            size={"small"}
            css={{ marginBottom: "8px" }}
            {...cmSelect}
          />
          <SelectField
            options={[{ label: "Default", value: "default" }]}
            inputName={"commercial"}
            label={"Responsable à contacter"}
            variant={"outlined"}
            color={"primary"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            multiple={false}
            readOnly={false}
            required={true}
            size={"small"}
            css={{ marginBottom: "8px" }}
            {...commercialSelect}
          />
        </Box>
        <Divider
          label={"Services"}
          chip={false}
          flexItem={false}
          light={false}
          orientation={"horizontal"}
          textAlign={"left"}
          variant={"fullWidth"}
          css={{ marginTop: "8px" }}
          Chip={
            <Chip
              label={"Chip label"}
              avatar={false}
              icon={false}
              clickable={false}
              color={"default"}
              variant={"filled"}
            />
          }
        />
        <Checkbox
          inputName={"services.rs"}
          label={"Réseaux sociaux"}
          color={"primary"}
          isChecked={false}
          disabled={false}
          disableTypography={false}
          aria-label={"Input Base Mui"}
          size={"small"}
        />
        <Checkbox
          inputName={"services.shootings"}
          label={"Shootings"}
          color={"primary"}
          isChecked={false}
          disabled={false}
          disableTypography={false}
          aria-label={"Input Base Mui"}
          size={"small"}
        />
        <Checkbox
          inputName={"services.influence"}
          label={"Influence"}
          color={"primary"}
          isChecked={false}
          disabled={false}
          disableTypography={false}
          aria-label={"Input Base Mui"}
          size={"small"}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          marginTop: "12px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          text={"Annuler"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
          {...back}
        />
        <Button
          text={"Valider"}
          type={"submit"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "6px",
            paddingBottom: "6px",
          }}
        />
      </Box>
    </Container>
  );
};

export default EmailForm;
// #endregion
