import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carrousel.css";

const Carrousel: React.FC<{ media: (string | undefined)[] }> = ({ media }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  if (!media.length) return null;

  if (media.length === 1)
    return <img src={media[0]} alt="" style={{ width: "100%" }} />;

  return (
    <Slider {...settings}>
      {media.map((src) => (
        <img key={src} src={src} alt="" style={{ width: "100%" }} />
      ))}
    </Slider>
  );
};

export default Carrousel;
