import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import { strapiInstance } from "@/providers/StrapiProvider";
import dayjs from "dayjs";
import { atom, selectorFamily } from "recoil";

type Params = {
  id?: string;
  date: string;
  status?: Exclude<Post["status"], "not-started">[];
};

export const postQueryParams = atom<{ date: string }>({
  key: "post-query-params",
  default: {
    date: dayjs().startOf("M").format(),
  },
});

export const postSelector = selectorFamily({
  key: "post-by-range",
  get: (params: Params) => async () => {
    const { date, status, id } = params;
    if (!id) return [];

    const res = await strapiInstance.find("posts", {
      filters: {
        account: {
          id: { $eq: id },
        },
        scheduledSendTime: {
          $between: [
            dayjs(date).startOf("M").format(),
            dayjs(date).endOf("M").format(),
          ],
        },
        ...(status?.length && {
          status: {
            $in: status,
          },
        }),
      },
      populate: {
        socialProfileIs: true,
        video: { populate: "*" },
        media: true,
        messages: {
          fields: ["id", "status"],
        },
      },
      pagination: {
        page: 1,
        pageSize: 100,
        withCount: true,
      },
    });

    return res?.data;
  },
});
