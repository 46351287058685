import React, { useMemo } from "react";
import LeadInsight from "../leads/LeadInsight";
import { Line, LineChart, ResponsiveContainer, Tooltip } from "recharts";
import { CustomTooltip } from "@/components/fields/charts/Line";
import StatusInsight from "../leads/StatusInsight";
import { useRecoilValue } from "recoil";
import { leadInsightByReportingId, leadStatus } from "@/store/leads";
import dayjs from "dayjs";

type Props = {
  id: number;
};

const LeadsReporting: React.FC<Props> = (props) => {
  const { id } = props;

  const insights = useRecoilValue(leadInsightByReportingId(id));

  const { data, count, status, date } = useMemo(() => {
    const data = Object.entries(insights)
      .sort((a, b) => (a[0] as any) - (b[0] as any))
      .map(([mounth, { count, ...status }]) => {
        const date = dayjs().month(parseInt(mounth) - 1);
        const startOfMonth = date.startOf("month").format("DD MMMM");
        const endOfMonth = date.endOf("month").format("DD MMMM");

        return {
          name: date.format("MMMM"),
          value: count,
          subValues: [],
          status,
          date: `${startOfMonth} - ${endOfMonth}`,
        };
      });

    const currentMounth = data[data.length - 1];

    return {
      data,
      count: currentMounth?.value,
      status: currentMounth?.status,
      date: currentMounth?.date,
    };
  }, [insights]);

  const statusList = useMemo(
    () =>
      Object.entries(status).map(([label, value]) => ({
        label: leadStatus[label],
        value,
      })),
    [status]
  );

  return (
    <LeadInsight
      chart={{
        children: (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <Tooltip
                content={<CustomTooltip unit={"Leads"} color={"#5900FF"} />}
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke={"#5900FF"}
                activeDot={{ r: 6 }}
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        ),
      }}
      status={{
        children: (
          <>
            {statusList.map(({ label, value }) => (
              <StatusInsight
                key={label}
                value={{ text: value.toString() }}
                label={{ text: label }}
              />
            ))}
          </>
        ),
      }}
      count={{ text: count.toString() }}
      rate={{ hidden: true }}
      date={{ text: date }}
    />
  );
};

export default LeadsReporting;
