/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
// #endregion

// #region Type Definition
type BrevoConfirmationProps = Partial<ContainerProps> & {
  name: TypographyProps & {
    email?: string,
  },
  back: ButtonProps & {},
  validate: ButtonProps & {},
};
// #endregion

// #region BrevoConfirmationComponent
const BrevoConfirmation: React.FC<BrevoConfirmationProps> = (props) => {
  const { name, back, validate, ...rootProps } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      {...rootProps}
    >
      <Typography
        variant={"body1"}
        text={
          "Êtes-vous sûr de vouloir envoyer ce <b>{{email}}</b>? Cette action est irréversible."
        }
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "18px", textAlign: "center" }}
        {...name}
      />
      <Box
        tag={"div"}
        css={{
          marginTop: "26px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          text={"Retour"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          color={"secondary"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          {...back}
        />
        <Button
          text={"Envoyer"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            paddingLeft: "18px",
            paddingRight: "18px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          {...validate}
        />
      </Box>
    </Container>
  );
};

export default BrevoConfirmation;
// #endregion
