import { strapiInstance } from "@/providers/StrapiProvider";
import { selectorFamily } from "recoil";

type Params = {
  id: number;
};

export const messagesByPost = selectorFamily({
  key: "messages-by-post",
  get:
    ({ id }: Params) =>
    async ({ get }) => {
      const res = await strapiInstance.find("messages", {
        filters: {
          post: {
            id: { $eq: id },
          },
        },
        populate: {
          author: {
            fields: ["id", "firstName", "lastName", "email"],
          },
        },
      });

      return (
        res?.data?.map(({ text, author, readBy }) => ({
          text,
          seen: true,
          user: {
            id: author?.id?.toString() || "user",
            name:
              (author?.firstName || author?.lastName
                ? `${author.firstName} ${author.lastName}`
                : author?.email) || "Utilisateur",
          },
        })) || []
      );
    },
});
