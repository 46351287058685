import { StrapiRequestParams } from "@/modules/strapi-sdk";
import { ContentType, CustomEndPoint } from "@/modules/strapi-sdk/lib/api";
import { useStrapi } from "@/providers/StrapiProvider";
import { GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useRef, useState } from "react";

export const useFetchPaginable = <T extends keyof ContentType>(
  contentType: T,
  params?: StrapiRequestParams<ContentType[T]>,
  pageSize = 7
) => {
  const [strapi] = useStrapi();
  const [data, setData] = useState<ContentType[T][]>();
  const [sort, setSort] = useState<GridSortModel>([
    { field: "date", sort: "desc" },
  ]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const loading = useRef(false);

  const fetchData = useCallback(() => {
    if (strapi && !loading.current) {
      loading.current = true;

      strapi
        ?.find(contentType, {
          ...params,
          sort: sort?.map(({ field, sort }) => `${field}:${sort}`),
          pagination: { page: page + 1, pageSize },
        })
        .then((res) => {
          setData(res?.data);
          setCount(res?.meta.pagination.total || 0);
        })
        .catch(() => {})
        .finally(() => (loading.current = false));
    }
  }, [contentType, page, pageSize, params, sort, strapi]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [
    { data, loading: loading.current, page, sort, count },
    { setSort, setPage, fetchData },
  ];
};

export const useStrapiFetch = <P extends keyof CustomEndPoint["GET"]>(
  path: P,
  params?: CustomEndPoint["GET"][P]["params"]
) => {
  const [strapi] = useStrapi();
  const loading = useRef(false);
  const [data, setData] = useState<CustomEndPoint["GET"][P]["result"]>();

  const fetch = useCallback(async () => {
    if (strapi && !loading.current) {
      try {
        loading.current = true;
        const res = await strapi.get(path, params);
        setData(res);
      } catch (error) {
      } finally {
        loading.current = false;
      }
    }
  }, [params, path, strapi]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return [{ data, loading: loading.current }, { refresh: fetch }];
};
