import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Drawer } from "@mui/material";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import {
  FacebookSkin,
  InstagramSkin,
  LinkedinSkin,
  PreviewPost,
  TiktokSkin,
} from "@/components";
import { useRecoilValue } from "recoil";
import { notionOptionByValue } from "@/store/notion";
import { accountSelector } from "@/store/accounts";
import Carrousel from "./Carrousel";
import Tabs, { Tab } from "./Tabs";
import HashTags from "./HashTags";
import Video from "./Video";
import Comments from "./Comments";
import { PistacheLoader } from "@/components/layout";
import { authState } from "@/store/auth";
import { useRefreshPlanning } from "@/hooks/planning";
import { useStrapi } from "@/providers/StrapiProvider";

type Props = {
  id: string;
  post?: Post;
  onClose: () => void;
  onRemovePost: (id: number) => void;
};

type Media =
  | {
      type: "carrousel";
      media: (string | undefined)[];
    }
  | {
      type: "video";
      media: {
        url: string;
        background: string;
      };
    };

const PlanningDrawer: React.FC<Props> = (props) => {
  const { id, post, onRemovePost } = props;

  const [strapi, { loading }] = useStrapi();

  const [reloadPlanning, setReloadPlanning] = useState(false);

  const option = useRecoilValue(
    notionOptionByValue({
      key: post?.status,
      collection: "livrables",
    })
  );
  const account = useRecoilValue(accountSelector(id));
  const user = useRecoilValue(authState);

  const refreshPlanning = useRefreshPlanning();

  const socials = useMemo(() => {
    const socials: any[] = [];
    if (post?.socialProfileIs) {
      const { facebook, instagram, linkedin, tiktok } = post.socialProfileIs;
      if (facebook) socials.push("facebook");
      if (instagram) socials.push("instagram");
      if (linkedin) socials.push("linkedin");
      if (tiktok) socials.push("tiktok");
    }
    return socials;
  }, [post?.socialProfileIs]);

  const media = useMemo<Media | undefined>(() => {
    if (post?.video?.video?.url && post.video.background?.url) {
      return {
        type: "video",
        media: {
          url: post.video.video.url,
          background: post.video.background?.url,
        },
      };
    }
    if (post?.media?.length) {
      if (post.media.length) {
        return {
          type: "carrousel",
          media: post.media.map(({ url }) => url) as string[],
        };
      }
    }
    return undefined;
  }, [post]);

  const [tab, setTab] = useState<Tab | undefined>(socials[0]);
  const activeTab = useMemo(() => tab || socials[0], [socials, tab]) as Tab;

  const config = useMemo(
    () => ({
      avatar: { src: account?.url },
      name: { text: account?.name },
      description: {
        text: post?.text,
      },
      tags: {
        children: <HashTags tags={post?.tags || []} tab={activeTab} />,
      },
      media: {
        children:
          media?.type === "carrousel" ? (
            <Carrousel media={media.media} />
          ) : (
            <Video
              url={media?.media.url}
              background={media?.media.background}
            />
          ),
      },
    }),
    [
      account?.name,
      account?.url,
      activeTab,
      media?.media,
      media?.type,
      post?.tags,
      post?.text,
    ]
  );

  const onClose = useCallback(() => {
    if (!reloadPlanning) return props.onClose();
    props.onClose();
    setTimeout(() => {
      refreshPlanning();
      setReloadPlanning(false);
    }, 300);
  }, [props, refreshPlanning, reloadPlanning]);

  const onOpenNotionLink = useCallback(async () => {
    if (!post?.id) return;
    const isValid = await strapi?.get("posts/notion/:id", { ":id": post.id });

    if (!isValid) {
      // close modal
      props.onClose();
      // remove from calendar
      onRemovePost(post.id);
    } else window.open(post?.notion_url, "_blank");
  }, [onRemovePost, post?.id, post?.notion_url, props, strapi]);

  useEffect(() => {
    if (!post?.id) setTab(undefined);
  }, [post?.id]);

  return (
    <Drawer
      open={!!post}
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          height: "calc(100% - 16px)",
          top: "8px",
          right: "12px",
          borderRadius: "18px",
        },
      }}>
      <PreviewPost
        name={{ text: post?.title }}
        socialTab={{
          children: (
            <Tabs
              socials={socials}
              tab={activeTab}
              onChange={(tab) => setTab(tab)}
            />
          ),
        }}
        body={{
          children: (
            <>
              {activeTab === "facebook" && <FacebookSkin {...config} />}
              {activeTab === "instagram" && <InstagramSkin {...config} />}
              {activeTab === "linkedin" && <LinkedinSkin {...config} />}
              {activeTab === "tiktok" && <TiktokSkin {...config} />}
              {activeTab === "comments" && post?.id && (
                <Suspense
                  fallback={<PistacheLoader css={{ height: "450px" }} />}>
                  <Comments
                    postId={post.id}
                    setReloadPlanning={setReloadPlanning}
                  />
                </Suspense>
              )}
            </>
          ),
        }}
        notion={{
          hidden: user?.type === "customer" || !post?.notion_url,
          onClick: onOpenNotionLink,
          disabled: loading,
        }}
        status={{
          className: option?.color,
          color: "info",
          variant: "outlined",
          label: option?.label,
          hidden: !option,
        }}
        close={{ onClick: onClose }}
      />
    </Drawer>
  );
};

export default PlanningDrawer;
