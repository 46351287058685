import React, { useCallback, useMemo, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useFetchPaginable } from "@/hooks/fetch";
import { Account } from "@/modules/strapi-sdk/lib/interfaces";
import dayjs from "dayjs";
import LeadModal from "./LeadModal";
import {
  formatDateRangeValue,
  leadInsightQuery,
  leadsParamsQuery,
  leadStatus,
} from "@/store/leads";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

type Props = {
  account: Account;
};

const LeadsTable: React.FC<Props> = (props) => {
  const { account } = props;
  const { category, range, status } = useRecoilValue(
    leadsParamsQuery(account.id as number)
  );
  const refreshInsights = useRecoilRefresher_UNSTABLE(
    leadInsightQuery(account.id)
  );
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const params = useMemo(
    () => ({
      filters: {
        sheet: {
          id: {
            $eq: account.sheet?.id,
          },
        },
        ...formatDateRangeValue(range),
        ...(category !== "ALL" && { category }),
        ...(status && { status }),
      },
    }),
    [account.sheet?.id, category, range, status]
  );

  const [selectedLead, setSelectedLead] = useState<number | undefined>(
    undefined
  );

  const [
    { data = [], loading, page = 0, sort, count },
    { setSort, setPage, fetchData },
  ] = useFetchPaginable("leads", params, 20);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        minWidth: 80,
        sortable: true,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.date && dayjs(row.date).format("DD/MM/YYYY");
        },
      },
      {
        field: "name",
        headerName: "Nom du lead",
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        type: "text",
      },
      {
        field: "email",
        headerName: "Email du lead",
        type: "text",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
      },
      {
        field: "phone",
        headerName: "Téléphone",
        type: "text",
        width: 180,
        sortable: false,
        disableColumnMenu: true,
      },
      {
        field: "status",
        headerName: "Statut",
        type: "text",
        width: 120,
        sortable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => {
          return row?.status ? leadStatus[row.status] : "";
        },
      },
      {
        field: "actions",
        sortable: false,
        disableColumnMenu: true,
        type: "actions",
        minWidth: 80,
        getActions: ({ row }) => [
          <Button
            color="primary"
            sx={{ padding: "4px 12px", fontSize: "12px" }}
            onClick={() => setSelectedLead(row.id)}>
            Voir
          </Button>,
        ],
      },
    ],
    []
  );

  const columnVisibilityModel = useMemo(
    () => ({
      status: !md,
      email: !md,
      phone: !md,
    }),
    [md]
  );

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      setSort?.(model);
    },
    [setSort]
  );
  const onPaginationModelChange = useCallback(
    (model: GridPaginationModel) => {
      setPage?.(model.page);
    },
    [setPage]
  );

  return (
    <Box
      sx={{
        height: "calc(100vh - 280px)",
        width: "100%",
        userSelect: "none",
        mt: 2,
      }}>
      <DataGrid
        rows={data}
        initialState={{
          columns: {
            columnVisibilityModel,
          },
        }}
        columns={columns}
        rowSelection={false}
        onSortModelChange={onSortModelChange}
        onPaginationModelChange={onPaginationModelChange}
        paginationModel={{ page, pageSize: 20 }}
        sortModel={sort}
        rowCount={count}
        paginationMode="server"
        loading={loading}
      />
      <LeadModal
        id={selectedLead}
        onClose={() => setSelectedLead(undefined)}
        refresh={() => {
          fetchData?.();
          refreshInsights();
        }}
      />
    </Box>
  );
};

export default LeadsTable;
