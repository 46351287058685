/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { BsQuestionCircle } from "react-icons/bs";
// #endregion

// #region Type Definition
type ModalAlertProps = Partial<ContainerProps> & {
  container: Partial<ContainerProps>,
  head: Partial<BoxProps>,
  icon: Partial<IconProps>,
  alertTiltle: TypographyProps & {},
  cancel: ButtonProps & {},
  confirm: ButtonProps & {},
  message: TypographyProps & {},
  alertContent: Partial<BoxProps>,
};
// #endregion

// #region ModalAlertComponent
const ModalAlert: React.FC<ModalAlertProps> = (props) => {
  const {
    container,
    head,
    icon,
    alertTiltle,
    cancel,
    confirm,
    message,
    alertContent,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={true}
      fixed={false}
      {...container}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{ display: "flex", alignItems: "center" }}
        {...head}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={BsQuestionCircle}
          css={{ fontSize: "48px" }}
          {...icon}
        />
        <Box tag={"div"} css={{ flexGrow: "1", paddingLeft: "12px" }}>
          <Typography
            variant={"h1"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{
              fontSize: "24px",
              "@media (max-width: 700px)": { fontSize: "18px" },
            }}
            {...alertTiltle}
          />
        </Box>
      </Box>
      <Typography
        variant={"caption"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{ fontSize: "14px", paddingTop: "8px" }}
        {...message}
      />
      <Box tag={"div"}>
        <Box tag={"div"} {...alertContent} />
        <Box
          tag={"div"}
          css={{
            marginTop: "16px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            text={"Annuler"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            color={"secondary"}
            iconStart={false}
            iconEnd={false}
            css={{
              paddingLeft: "14px",
              paddingRight: "14px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            {...cancel}
          />
          <Button
            text={"Confirmer"}
            type={"submit"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            iconStart={false}
            iconEnd={false}
            css={{
              paddingLeft: "14px",
              paddingRight: "14px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            {...confirm}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default ModalAlert;
// #endregion
