import React, { Suspense, useCallback } from "react";
import Layout, { PistacheLoader } from "@/components/layout";
import { useRecoilState, useRecoilValue } from "recoil";
import { accountUserState } from "@/store/auth";
import { LeadsList, NotFound } from "@/components";
import Categories from "@/components/features/leads/Categories";
import SkeletonCategory from "@/components/features/leads/SkeletonCategory";
import DateRangePicker, {
  DateRangeValue,
} from "@/components/fields/DateRangePicker";
import LeadsTable from "@/components/features/leads/LeadsTable";
import { leadsParamsQuery } from "@/store/leads";

const Leads: React.FC = () => {
  return (
    <Layout mode="CUSTOMER" title="Pistache - Leads" name="Leads">
      <Suspense
        fallback={<PistacheLoader css={{ height: "calc(100vh - 110px)" }} />}>
        <Component />
      </Suspense>
    </Layout>
  );
};

export default Leads;

const Component: React.FC = () => {
  const account = useRecoilValue(accountUserState);
  const [{ range }, setLeadsParam] = useRecoilState(
    leadsParamsQuery(account?.id as number)
  );

  const onDateRangeChange = useCallback(
    (range: DateRangeValue) => {
      setLeadsParam((prev) => ({ ...prev, range }));
    },
    [setLeadsParam]
  );
  if (!account?.id || !account.sheet?.id) return <NotFound />;

  return (
    <LeadsList
      table={{
        children: <LeadsTable account={account} />,
      }}
      categories={{
        children: (
          <Suspense fallback={<SkeletonCategory />}>
            <Categories id={account.id} />
          </Suspense>
        ),
      }}
      datePicker={{
        children: (
          <DateRangePicker value={range} onChange={onDateRangeChange} />
        ),
      }}
      resync={{ hidden: true }}
    />
  );
};
