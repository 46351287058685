/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Icon, Typography, TypographyProps } from "@repleek/mui";
import { BsPeople } from "react-icons/bs";
// #endregion

// #region Type Definition
type CategoryLeadCardProps = Partial<BoxProps> & {
  category: TypographyProps & {},
  count: TypographyProps & {},
};
// #endregion

// #region CategoryLeadCardComponent
const CategoryLeadCard: React.FC<CategoryLeadCardProps> = (props) => {
  const { category, count, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        minWidth: "160px",
        maxWidth: "260px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        gap: "14px",
        borderRadius: "12px",
        paddingLeft: "16px",
        paddingRight: "8px",
        paddingTop: "8px",
        paddingBottom: "8px",
        cursor: "pointer",
        userSelect: "none",
        display: "flex",
        alignItems: "center",
        ":hover": {
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px , rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        },
        "&.selected": {
          boxShadow: "rgba(83, 254, 122, 0.49) 0px 0px 0px 3px ",
        },
      }}
      {...rootProps}
    >
      <Icon
        color={"inherit"}
        fontSize={"inherit"}
        icon={BsPeople}
        css={{
          fontSize: "32px",
          "@media (max-width: 980px)": { fontSize: "22px" },
        }}
      />
      <Box tag={"div"} css={{ flexGrow: "1" }}>
        <Typography
          variant={"overline"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ lineHeight: "16px" }}
          {...category}
        />
        <Typography
          variant={"h1"}
          text={"15"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "32px", color: "rgba(89, 0, 255, 1)" }}
          {...count}
        />
      </Box>
    </Box>
  );
};

export default CategoryLeadCard;
// #endregion
