import React, { useCallback, useMemo, useState } from "react";
import { Modal, ModalProps, PistacheLoader } from "@/components/layout";
import { Box, Step, StepButton, Stepper } from "@mui/material";
import ModelItem from "./ModelItem";
import EmailForm from "./EmailForm";
import EmailResume from "./EmailResume";
import { useStrapi } from "@/providers/StrapiProvider";
import { EmailModel, Template, EmailType, BrevoTemplate } from "@/interfaces";
import { toast } from "react-toastify";
import { IoIosPaperPlane } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import BrevoConfirmation from "./BrevoConfirmation";
import DatePicker from "@/components/fields/DatePicker";
import dayjs from "dayjs";
import { useRecoilValue } from "recoil";
import { cmUsersByWorkspace } from "@/store/workspace";

type Props = Pick<ModalProps, "open" | "onClose"> & {
  model?: keyof EmailModel;
  models?: (keyof EmailModel)[];
  email?: string;
  content?: { [k in Template]?: Partial<EmailType> } | any;
  loading?: boolean;
};

const commercial = [
  { label: "Yoann - yoann@m-com.fr", value: "yoann@m-com.fr" },
  {
    label: "Gregory - gregory@agence-pistache.fr",
    value: "gregory@agence-pistache.fr",
  },
  { label: "Eddy - e.vella@m-com.fr", value: "e.vella@m-com.fr" },
  { label: "Noémie - noemie@m-com.fr", value: "noemie@m-com.fr" },
  { label: "Nassim - n.maresni@m-com.fr", value: "n.maresni@m-com.fr" },
];

const serviceLabels: Record<string, string> = {
  rs: "vos réseaux sociaux",
  shootings: "les shootings",
  influence: "l'influence",
  facturation: "la facturation",
};

const socialLabels: Record<string, string> = {
  facebook: "Facebook",
  instagram: "Instagram",
  tiktok: "TikTok",
  linkedin: "LinkedIn",
};

const stringifyServices = (services: Record<string, boolean> | string) => {
  return Object.entries(services)
    .filter(([, value]) => value)
    .reduce((acc, [key, value], currIndex, arr) => {
      const label = serviceLabels[key];
      if (!value && label) return acc;

      return acc
        ? [acc, label].join(currIndex === arr.length - 1 ? " ou " : ", ")
        : label;
    }, "");
};

const customTemplateForm: (Template | keyof BrevoTemplate)[] = [
  "ACCESS_REQUEST",
  "PLANNING",
  "RS_PUBLISH",
  "UNSUBSCRIBE",
  "WELCOME",
];

const defaultBrevoValues = {
  cm: undefined,
  commercial: undefined,
  services: {
    facturation: true,
    influence: true,
    rs: true,
    shootings: true,
  },
};

const EmailModal: React.FC<Props> = (props) => {
  const { model, models, email, content, onClose, ...attr } = props;

  const [selectedModel, setSelectedModel] = useState<
    keyof EmailModel | undefined
  >(model);
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState<
    | EmailType
    | EmailModel[
        | "RS_PUBLISH"
        | "UNSUBSCRIBE"
        | "ACCESS_REQUEST"
        | "PLANNING"
        | "WELCOME"]["value"]
  >();
  const [strapi, { loading }] = useStrapi();
  const cm = useRecoilValue(cmUsersByWorkspace);

  const modelDefinition = useMemo<EmailModel>(() => {
    const model: EmailModel = {
      welcomeEmail: {
        value: {
          subject: "Bienvenue sur notre plateforme !",
          title: "🚀 Bienvenue sur notre plateforme 🚀",
          message:
            "Cliquez sur le bouton ci-dessous pour activer votre compte.",
          cta: {
            label: "Activer mon compte",
            link: "https://dashboard.agence-pistache.fr",
          },
        },
        name: "Email d'activation de compte",
        description:
          "Message d'activation de compte pour les nouveaux comptes.",
        provider: "default",
      },
      resetPassword: {
        value: {
          subject: "Réinitialisation de votre mot de passe",
          title: "Réinitialisation de votre mot de passe",
          message:
            "Cliquez sur le bouton ci-dessous pour réinitialiser votre mot de passe.",
          cta: {
            label: "Réinitialiser mon mot de passe",
            link: "https://dashboard.agence-pistache.fr",
          },
        },
        name: "Réinitialisation de mot de passe",
        description:
          "Email envoyé aux utilisateurs pour réinitialiser leur mot de passe.",
        provider: "default",
      },
      reporting: {
        value: {
          subject: "Nouveau rapport disponnible!",
          title: "🔔 Nouveau rapport disponnible 🔔",
          message:
            "Votre rapport récemment généré est maintenant accessible sur votre compte. N'hésitez pas à le consulter dès à présent pour prendre connaissance de son contenu.",
          cta: {
            label: "Consulter mon compte",
            link: "https://dashboard.agence-pistache.fr",
          },
        },
        name: "Email de notification (reporting)",
        description: "Email de notification pour les rapports ou les alertes.",
        provider: "default",
      },
      DEFAULT: {
        value: {
          subject: "",
          title: "",
          message: "",
          cta: {
            label: "Mon compte",
            link: "https://dashboard.agence-pistache.fr",
          },
          ...defaultBrevoValues,
        },
        name: "Rédiger votre email",
        description:
          "Modèle d'email par défaut avec la possibilité pour l'utilisateur de personnaliser le contenu.",
        provider: "brevo",
      },
      WELCOME: {
        provider: "brevo",
        name: "Mail de bienvenue (Brevo)",
        description: "Message de bienvenue pour les nouveaux utilisateurs.",
        value: {
          firstName: "",
          ...defaultBrevoValues,
        },
      },
      PLANNING: {
        provider: "brevo",
        name: "Envoi de Planning (Brevo)",
        description:
          "Envoyez un email expliquant comment utiliser la Pist'APP pour consulter et valider le planning.",
        value: {
          firstName: "",
          ...defaultBrevoValues,
        },
      },
      RS_PUBLISH: {
        provider: "brevo",
        name: "Mail de démarrage de diffusion (Brevo)",
        description:
          "Ce mail est envoyé pour informer qu'une diffusion a été démarrée. Il contient les informations d'accès nécessaires.",
        value: {
          access: {
            instagram: undefined,
            tiktok: undefined,
            facebook: true,
            linkedin: true,
          },
          firstName: "",
          ...defaultBrevoValues,
        },
      },
      ACCESS_REQUEST: {
        provider: "brevo",
        name: "Mail de récupération des accès (Brevo)",
        description:
          "Ce mail est envoyé pour fournir les accès. Il peut inclure les identifiants et instructions nécessaires.",
        value: {
          facebook: true,
          linkedin: false,
          gmb: true,
          instagram: true,
          ...defaultBrevoValues,
        },
      },
      UNSUBSCRIBE: {
        provider: "brevo",
        name: "Mail de résiliation (Brevo)",
        description:
          "Ce mail confirme la résiliation d'un service et inclut des informations sur la dernière publication, la dernière campagne publicitaire, et le dernier paiement.",
        value: {
          lastAdCampaign: undefined,
          lastPayment: undefined,
          lastPublication: undefined,
          ...defaultBrevoValues,
          socials: {
            facebook: true,
            instagram: true,
            linkedin: true,
            tiktok: true,
          },
        },
      },
    };

    if (models) {
      return models.reduce((acc, curr) => {
        if (model[curr]) acc[curr] = model[curr] as any;
        return acc;
      }, {} as EmailModel);
    }

    return model;
  }, [models]);

  const defaultValues = useMemo(() => {
    if (data) return data;
    return {
      ...modelDefinition[selectedModel || "DEFAULT"].value,
      ...content?.[selectedModel || "default"],
      email,
    };
  }, [content, data, email, modelDefinition, selectedModel]);

  const setNextStep = useCallback(() => {
    const currStep = activeStep;
    setActiveStep(currStep + 1);
  }, [activeStep]);

  const setBackStep = useCallback(() => {
    const currStep = activeStep;
    setActiveStep(currStep - 1);
  }, [activeStep]);

  const onSelectModel = useCallback(
    (model: keyof EmailModel) => {
      setSelectedModel(model);
      setNextStep();
    },
    [setNextStep]
  );

  const handleSave = useCallback(
    (values: EmailType) => {
      setData(values);
      setNextStep();
    },
    [setNextStep]
  );

  const onCloseModal = useCallback(() => {
    onClose();
    setActiveStep(0);
    setSelectedModel(undefined);
  }, [onClose]);

  const sendEmail = useCallback(
    async (template: keyof BrevoTemplate) => {
      if (!email) return;

      let success = false;

      const params = data as BrevoTemplate[
        | "ACCESS_REQUEST"
        | "RS_PUBLISH"
        | "UNSUBSCRIBE"]["value"];

      if (params.services) {
        const service = stringifyServices(params.services);
        params.services = service;
      }

      if (template === "UNSUBSCRIBE") {
        const { socials } = params as BrevoTemplate["UNSUBSCRIBE"]["value"];

        if (socials && "facebook" in socials) {
          (params as BrevoTemplate["UNSUBSCRIBE"]["value"]).socials = {
            remove_access: [
              socials.facebook && "Facebook",
              socials.linkedin && "LinkedIn",
            ]
              .filter((v) => v)
              .join(" & "),
            retreive: [
              socials.instagram && "Instagram",
              socials.tiktok && "TikTok",
            ]
              .filter((v) => v)
              .join(" & "),
          };
        }

        if ("lastPublication" in params)
          params.lastPublication = dayjs(params.lastPublication).format(
            "DD MMMM YYYY"
          );
        if ("lastAdCampaign" in params)
          params.lastAdCampaign = dayjs(params.lastAdCampaign).format(
            "DD MMMM YYYY"
          );
        if ("lastPayment" in params)
          params.lastPayment = dayjs(params.lastPayment).format("DD MMMM YYYY");
      }

      if (template === "RS_PUBLISH" && "access" in params) {
        if (params.strategy) {
          if (params.strategy.photos)
            params.strategy.photos =
              (params.strategy.photos as number) > 1
                ? `${params.strategy.photos} photos/visuels`
                : `${params.strategy.photos} photo/visuel`;
          if (params.strategy?.video)
            params.strategy.video = `et ${params.strategy.video} vidéo`;

          params.strategy.socials = Object.entries(params.access)
            .reduce((acc, [key, value]) => {
              if (typeof value === "boolean")
                value && acc.push(socialLabels[key]);
              else value.username && acc.push(socialLabels[key]);
              return acc;
            }, [] as string[])
            .join(", ");
          if (typeof params.visibility === "object")
            params.visibility = Object.entries(params.visibility)
              .reduce((acc, [key, value]) => {
                if (value) acc.push(socialLabels[key]);
                return acc;
              }, [] as string[])
              .join(", ");
        }
      }

      success = !!(await strapi?.post(
        "send-brevo-transactionnal",
        {},
        {
          template,
          destinations: [{ email }],
          params,
        }
      ));
      if (success) {
        toast.success("Notification envoyée");
        onCloseModal();
      }
    },
    [data, email, onCloseModal, strapi]
  );

  const steps = useMemo(() => {
    let steps = [];
    if (!model)
      steps.push({
        label: "Choisissez un modèle",
        step: Object.entries(modelDefinition).map(
          ([model, { name, description, provider }]) => (
            <ModelItem
              key={model}
              item={{
                onClick: () => onSelectModel(model as keyof EmailModel),
                style: { transition: "all 300ms" },
              }}
              icon={{
                ...(selectedModel === model && {
                  icon: IoCheckmarkCircle,
                  color: "primary",
                }),
              }}
              name={{ text: name }}
              description={{ text: description }}
            />
          )
        ),
      });

    if (selectedModel) {
      const withFirstName = !["WELCOME", "RS_PUBLISH", "PLANNING"].includes(
        selectedModel
      );

      steps = [
        ...steps,
        {
          label: "Rédigez votre e-mail",
          step: (
            <EmailForm
              name={{ text: modelDefinition[selectedModel || "default"].name }}
              back={{
                text: "Retour",
                onClick: () => {
                  setBackStep();
                  setData(undefined);
                },
              }}
              email={{ disabled: !!email, inputName: "email" }}
              defaultForm={{
                hidden: !!customTemplateForm.includes(selectedModel),
              }}
              firstName={{ hidden: withFirstName }}
              rsPublish={{ hidden: selectedModel !== "RS_PUBLISH" }}
              unsubscribe={{ hidden: selectedModel !== "UNSUBSCRIBE" }}
              unsubscribeDate={{
                children: (
                  <>
                    <DatePicker
                      name="lastPublication"
                      label="Dernière publication"
                    />
                    <DatePicker name="lastAdCampaign" label="Fin de campagne" />
                    <DatePicker
                      name="lastPayment"
                      label="Dernier prélèvement"
                    />
                  </>
                ),
              }}
              accessRequest={{ hidden: selectedModel !== "ACCESS_REQUEST" }}
              cmSelect={{ options: cm }}
              commercialSelect={{ options: commercial }}
            />
          ),
        },
      ];
    }

    steps = [
      ...steps,
      {
        label: "Confirmation",
        step:
          selectedModel && customTemplateForm.includes(selectedModel) ? (
            <BrevoConfirmation
              back={{ onClick: setBackStep }}
              validate={{
                onClick: () => sendEmail(selectedModel as keyof BrevoTemplate),
              }}
              name={{ email: modelDefinition[selectedModel || "default"].name }}
            />
          ) : (
            <EmailResume
              email={{ email }}
              subject={{ subject: (data as EmailType)?.subject }}
              headLine={{ text: (data as EmailType)?.title }}
              message={{ text: (data as EmailType)?.message }}
              buttonName={{ text: (data as EmailType)?.cta.label }}
              edit={{ onClick: setBackStep }}
              validate={{ onClick: () => sendEmail("DEFAULT") }}
            />
          ),
      },
    ];

    return steps;
  }, [
    cm,
    data,
    email,
    model,
    modelDefinition,
    onSelectModel,
    selectedModel,
    sendEmail,
    setBackStep,
  ]);

  return (
    <Modal
      type="form"
      title={"Centre de notification par e-mail"}
      icon={{ icon: IoIosPaperPlane }}
      width="md"
      noActions
      onClose={onCloseModal}
      form={{ defaultValues, onSubmit: handleSave }}
      {...attr}>
      <Box sx={{ width: "100%", mt: 2, mb: 2, minHeight: "240px" }}>
        <Stepper nonLinear activeStep={activeStep} sx={{ mb: 2 }}>
          {steps.map(({ label }, index) => (
            <Step key={label} completed={index < activeStep}>
              <StepButton color="inherit" disableRipple>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ maxHeight: "480px", overflowY: "auto", py: 2 }}>
          {loading || attr.loading ? (
            <PistacheLoader />
          ) : (
            steps[activeStep].step
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default EmailModal;
