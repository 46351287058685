/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  Image,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { FaCaretRight } from "react-icons/fa";
// #endregion

// #region Type Definition
type ModelItemProps = Partial<ContainerProps> & {
  item: Partial<ContainerProps>,
  icon: Partial<IconProps>,
  name: TypographyProps & {},
  description: TypographyProps & {},
};
// #endregion

// #region ModelItemComponent
const ModelItem: React.FC<ModelItemProps> = (props) => {
  const { item, icon, name, description, ...rootProps } = props;

  return (
    <Container
      maxWidth={"xs"}
      disableGutters={false}
      fixed={false}
      css={{
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "#0B0B0B",
        boxShadow: "rgba(240, 46, 170, 0.4) 5px 5px",
        gap: "8px",
        borderRadius: "16px",
        marginBottom: "12px",
        paddingTop: "12px",
        paddingBottom: "12px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        ":hover": { boxShadow: "rgba(240, 46, 170, 0.4) 0px 0px   " },
      }}
      {...item}
      {...rootProps}
    >
      <Box tag={"div"} css={{ flexGrow: "1" }}>
        <Box
          tag={"div"}
          css={{ gap: "8px", display: "flex", alignItems: "center" }}
        >
          <Image
            alt={"Image description for better SEO"}
            src={"/images/logo-brevo.png"}
            dynamic={true}
            css={{ height: "28px" }}
          />
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "16px" }}
            {...name}
          />
        </Box>
        <Typography
          variant={"caption"}
          text={"Mon titre ici"}
          gutterBottom={false}
          noWrap={false}
          css={{ fontSize: "14px", color: "#5C5D63" }}
          {...description}
        />
      </Box>
      <Icon
        color={"secondary"}
        fontSize={"inherit"}
        icon={FaCaretRight}
        css={{ fontSize: "24px" }}
        {...icon}
      />
    </Container>
  );
};

export default ModelItem;
// #endregion
