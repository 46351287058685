import { JWTSession } from "@/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";
import { socialsAuthState } from "@/store/auth";
import { decodeHtml, ssoOauth } from "@/utils";
import { Button } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";

type IWindowProps = {
  url: string;
  title: string;
  width?: number;
  height?: number;
};

const createPopup = (params: IWindowProps) => {
  const { url, title, height = 500, width = 500 } = params;
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    title,
    `width=${width},height=${height},left=${left},top=${top}`
  );
  return externalPopup;
};

type OAuthPopupPorps = {
  label?: string;
  type: keyof typeof ssoOauth;
  onSuccess: (code: JWTSession) => void;
};

const OAuthPopup: React.FC<OAuthPopupPorps> = (props) => {
  const { type, onSuccess, label = "Connexion" } = props;

  const [externalWindow, setExternalWindow] = useState<Window | null>(null);
  const timer = useRef<number | null>(null);
  const [strapi] = useStrapi();

  const opened = useMemo(
    () => (externalWindow ? !externalWindow.closed : false),
    [externalWindow]
  );
  const { url, title } = useMemo(() => ssoOauth[type], [type]);

  const setJWTSession = useSetRecoilState(socialsAuthState(type));

  const closePopup = useCallback(() => {
    externalWindow?.close();
    setExternalWindow(null);
    window.removeEventListener("message", onMessage);
    if (timer.current) clearInterval(timer.current);
  }, [externalWindow]);

  const onMessage = useCallback(
    async (event: MessageEvent<any>) => {
      if (["https://dashboard.agence-pistache.fr"].includes(event.origin)) {
        if (event.data) {
          const { source, provider, code, error, error_description } =
            event.data;
          if (source === "oauth-popup" && type === provider) {
            if (error) {
              console.log(error, decodeHtml(error_description));
              toast(decodeHtml(error_description), { type: "error" });
            }
            if (code) {
              const res = await strapi?.post(
                "sso/:provider/callback",
                { ":provider": provider },
                { code }
              );
              if (res) {
                setJWTSession(res);
                onSuccess(res);
              }
            }
            closePopup();
          }
        }
      }
    },
    [closePopup, onSuccess, setJWTSession, strapi, type]
  );

  const onContainerClick = () => {
    // create and open popup
    setExternalWindow(
      createPopup({
        url,
        title,
        width: 640,
        height: 720,
      })
    );
    // listen popUp message
    window.addEventListener("message", onMessage);
  };

  useEffect(() => {
    if (externalWindow) {
      //check if popup is closed
      timer.current = window.setInterval(() => {
        if (externalWindow?.closed) {
          closePopup();
        }
      }, 700);
    }
    return () => {
      if (externalWindow) closePopup();
    };
  }, [closePopup, externalWindow]);

  return (
    <Button
      variant="contained"
      onClick={onContainerClick}
      disabled={opened}
      fullWidth
      sx={{ padding: "8px 14px" }}>
      {label}
    </Button>
  );
};

export default OAuthPopup;
