/** Build with repleek */
// #region Import
import React from "react";

import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Button,
  ButtonIcon,
  ButtonIconProps,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  Paper,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoIosPaperPlane } from "react-icons/io";
import { IoCopy } from "react-icons/io5";
import { SiNotion } from "react-icons/si";
// #endregion

// #region Type Definition
type AccountDetailProps = Partial<ContainerProps> & {
  logo: Partial<AvatarProps>,
  editBtn: ButtonProps & {},
  name: TypographyProps & {},
  email: TypographyProps & {},
  notification: Partial<ButtonIconProps>,
  statisticTab: ButtonProps & {},
  reportingTab: ButtonProps & {},
  planningTab: ButtonProps & {},
  hootsuite: Partial<ContainerProps>,
  hootsuitId: TypographyProps & {},
  copy: ButtonProps & {},
  notionUrl: Partial<ButtonIconProps>,
  comunityManagers: Partial<BoxProps>,
  leadsTab: ButtonProps & {},
  gmbTab: ButtonProps & {},
};
// #endregion

// #region AccountDetailComponent
const AccountDetail: React.FC<AccountDetailProps> = (props) => {
  const {
    logo,
    editBtn,
    name,
    email,
    notification,
    statisticTab,
    reportingTab,
    planningTab,
    hootsuite,
    hootsuitId,
    copy,
    notionUrl,
    comunityManagers,
    leadsTab,
    gmbTab,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"lg"}
      disableGutters={false}
      fixed={false}
      css={{ marginTop: "32px", marginBottom: "32px" }}
      {...rootProps}
    >
      <Paper
        square={true}
        variant={"elevation"}
        elevation={0}
        css={{
          padding: "24px",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          gap: "12px",
          borderRadius: "22px",
          display: "flex",
          alignItems: "center",
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Avatar
          variant={"rounded"}
          component={"div"}
          css={{ width: "64px", height: "64px" }}
          {...logo}
        />
        <Box tag={"div"} css={{ flexGrow: "1", paddingLeft: "16px" }}>
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ maxWidth: "480px", fontSize: "26px" }}
            {...name}
          />
          <Typography
            variant={"body1"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            {...email}
          />
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              marginTop: "8px",
              display: "flex",
              flexWrap: "wrap",
            }}
            {...comunityManagers}
          />
        </Box>
        <Box tag={"div"}>
          <ButtonIcon
            type={"button"}
            disabled={false}
            disableFocusRipple={false}
            disableRipple={false}
            color={"secondary"}
            css={{ marginRight: "8px" }}
            Icon={
              <Icon color={"inherit"} fontSize={"inherit"} icon={SiNotion} />
            }
            {...notionUrl}
          />
          <ButtonIcon
            type={"button"}
            disabled={false}
            disableFocusRipple={false}
            disableRipple={false}
            color={"secondary"}
            css={{ marginRight: "8px" }}
            Icon={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={IoIosPaperPlane}
              />
            }
            {...notification}
          />
          <Button
            text={"Modifier"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            iconStart={false}
            iconEnd={false}
            css={{
              paddingLeft: "12px",
              paddingRight: "12px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            {...editBtn}
          />
        </Box>
      </Paper>
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          backgroundColor: "rgba(255,255,255, 1)",
          boxShadow:
            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
          marginTop: "22px",
          borderRadius: "36px",
          ".action-tab": {
            boxShadow: "rgba(0, 0, 0, 0.06) 0px 2px 4px 0px ",
            borderStyle: "none",
            marginRight: "4px",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "8px",
            paddingBottom: "8px",
          },
          ".mui-dark &": { backgroundColor: "rgba(39, 37, 45, 1)" },
        }}
      >
        <Button
          text={"Statistiques"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          iconStart={false}
          iconEnd={false}
          className={"action-tab"}
          {...statisticTab}
        />
        <Button
          text={"Compte rendu"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          iconStart={false}
          iconEnd={false}
          className={"action-tab"}
          {...reportingTab}
        />
        <Button
          text={"PLanning"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          iconStart={false}
          iconEnd={false}
          className={"action-tab"}
          {...planningTab}
        />
        <Button
          text={"Leads"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          iconStart={false}
          iconEnd={false}
          className={"action-tab"}
          {...leadsTab}
        />
        <Button
          text={"Google Business"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          iconStart={false}
          iconEnd={false}
          className={"action-tab"}
          {...gmbTab}
        />
      </Box>
      <Container
        maxWidth={"sm"}
        disableGutters={false}
        fixed={false}
        css={{ marginTop: "32px" }}
        {...hootsuite}
      >
        <Box
          tag={"div"}
          className={"bg-white"}
          css={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            borderRadius: "32px",
            paddingLeft: "22px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant={"h3"}
            text={"Mon titre ici"}
            gutterBottom={false}
            noWrap={false}
            css={{ fontSize: "17px", flexGrow: "1" }}
            {...hootsuitId}
          />
          <Button
            text={"Copy"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"small"}
            variant={"contained"}
            iconStart={true}
            iconEnd={false}
            css={{
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px , rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            }}
            IconStart={
              <Icon color={"inherit"} fontSize={"inherit"} icon={IoCopy} />
            }
            {...copy}
          />
        </Box>
      </Container>
    </Container>
  );
};

export default AccountDetail;
// #endregion
