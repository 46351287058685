import React, { Suspense, useCallback } from "react";
import { Box } from "@mui/material";
import { Modal, PistacheLoader } from "@/components/layout";
import GMBPageList from "./GMBPageList";

type Props = {
  open: boolean;
  wkId: number;
  onClose: () => void;
};

const GMBSelectModal: React.FC<Props> = (props) => {
  const { onClose, open, wkId } = props;

  const onLink = useCallback((data?: unknown) => {}, []);

  return (
    <Modal open={open} onClose={onClose} noHeader type="box" noActions>
      <Suspense
        fallback={
          <Box sx={{ minWidth: "340px" }}>
            <PistacheLoader />
          </Box>
        }>
        <GMBPageList onLink={onLink} wkId={wkId} />
      </Suspense>
    </Modal>
  );
};

export default GMBSelectModal;
