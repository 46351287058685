/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
// #endregion

// #region Type Definition
type LeadInsightProps = Partial<ContainerProps> & {
  status: Partial<BoxProps>,
  chart: Partial<BoxProps>,
  date: TypographyProps & {},
  count: TypographyProps & {},
  rate: Partial<BoxProps>,
};
// #endregion

// #region LeadInsightComponent
const LeadInsight: React.FC<LeadInsightProps> = (props) => {
  const { status, chart, date, count, rate, ...rootProps } = props;

  return (
    <Container
      maxWidth={"lg"}
      disableGutters={true}
      fixed={false}
      className={"bg-white"}
      css={{
        padding: "16px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        marginTop: "16px",
        marginBottom: "16px",
        borderRadius: "16px",
        ".mui-dark &": {
          boxShadow:
            "rgba(255, 255, 255, 0.02) 0px 1px 3px 0px  , rgba(252, 252, 252, 0.15) 0px 0px 0px 1px ",
        },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          padding: "16px",
          display: "flex",
          "@media (max-width: 700px)": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Box
          tag={"div"}
          css={{ flexGrow: "1", display: "flex", flexDirection: "column" }}
        >
          <Box tag={"div"} css={{ flexGrow: "1" }}>
            <Typography
              variant={"h3"}
              text={"Performance Lead"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "22px", marginBottom: "8px" }}
            />
            <Typography
              variant={"body1"}
              text={"01 septembre - 30 septembre"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "16px" }}
              {...date}
            />
          </Box>
          <Box
            tag={"div"}
            css={{ gap: "8px", display: "flex", alignItems: " first baseline" }}
          >
            <Typography
              variant={"h1"}
              text={"1500"}
              gutterBottom={false}
              noWrap={false}
              css={{ fontSize: "58px", color: "rgba(89, 0, 255, 1)" }}
              {...count}
            />
            <Box
              tag={"div"}
              css={{ gap: "8px", display: "flex", alignItems: "flex-end" }}
              {...rate}
            >
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={IoArrowUp}
                css={{
                  padding: "4px",
                  backgroundColor: "rgba(219, 237, 224, 1)",
                  fontSize: "26px",
                  color: "rgba(101, 177, 121, 1)",
                  borderRadius: "16px",
                }}
              />
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={IoArrowDown}
                css={{
                  padding: "4px",
                  backgroundColor: "rgb(251, 199, 199)",
                  fontSize: "26px",
                  color: "rgb(244, 80, 80)",
                  borderRadius: "16px",
                }}
              />
              <Typography
                variant={"h3"}
                text={"15%"}
                gutterBottom={false}
                noWrap={false}
                css={{ fontSize: "24px" }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          tag={"div"}
          css={{
            minWidth: "560px",
            minHeight: "200px",
            "@media (max-width: 980px)": { minWidth: "460px" },
            "@media (max-width: 700px)": { minWidth: "380px" },
            "@media (max-width: 480px)": {
              minWidth: "300px",
              minHeight: "140px",
            },
          }}
          {...chart}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          gap: "8px",
          marginTop: "16px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
        {...status}
      />
    </Container>
  );
};

export default LeadInsight;
// #endregion
