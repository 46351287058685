import { JWTSession, Provider } from "@/interfaces";
import { StrapiUser } from "@/modules/strapi-sdk";
import { strapiInstance } from "@/providers/StrapiProvider";
import { DefaultValue, atom, atomFamily, selector } from "recoil";

export const authState = atom<undefined | StrapiUser>({
  key: "auth-state",
  default: undefined,
});

export const socialsAuthState = atomFamily<JWTSession | undefined, Provider>({
  key: "socials-auth-state",
  default: undefined,
  effects: (provider) => [
    ({ setSelf, onSet }) => {
      if (provider) {
        const key = `${provider}_jwt`;
        const savedValue = localStorage.getItem(key);
        if (savedValue) {
          try {
            const data =
              savedValue !== "undefined" ? JSON.parse(savedValue) : undefined;
            setSelf(data);
          } catch (error) {
            console.log("error: ", error);
            setSelf(undefined);
          }
        }

        onSet((newValue) => {
          if (newValue instanceof DefaultValue || !newValue) {
            localStorage.removeItem(key);
          } else {
            localStorage.setItem(key, JSON.stringify(newValue));
          }
        });
      }
    },
  ],
});

export const accountUserState = selector({
  key: "account-user-state",
  get: async ({ get }) => {
    const user = get(authState);
    const res = await strapiInstance.find("accounts", {
      filters: {
        email: {
          $eq: user?.email,
        },
      },
      populate: {
        avatar: true,
        sheet: true,
      },
    });

    if (res?.data?.length) {
      return res.data[0];
    }

    return undefined;
  },
});
