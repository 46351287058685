import React, { PropsWithChildren, useMemo } from "react";
import { useThemeChange } from "@/hooks/layout";
import { MinChatUiProvider } from "@minchat/react-chat-ui";
import { useColorMode } from "@repleek/mui";

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { mode } = useColorMode();
  useThemeChange();

  const colorSet = useMemo(
    () => (mode === "dark" ? chatColorDark : undefined),
    [mode]
  );

  return (
    <MinChatUiProvider theme="#53FE7A" colorSet={colorSet}>
      {children}
    </MinChatUiProvider>
  );
};

export default AppProvider;

const chatColorDark = {
  // input
  "--input-background-color": "#47454c",
  "--input-text-color": "#fff",
  "--input-element-color": "#47454c",
  "--input-attach-color": "#fff",
  "--input-send-color": "#fff",
  "--input-placeholder-color": "rgb(255, 255, 255)",

  // message header
  "--message-header-background-color": "white",
  "--message-header-text-color": "#fff",
  "--message-header-last-active-color": "#47454c",
  "--message-header-back-color": "rgb(255, 255, 255)",

  // chat list header
  "--chatlist-header-background-color": "white",
  "--chatlist-header-text-color": "rgb(255, 255, 255)",
  "--chatlist-header-divider-color": "rgb(0, 128, 0)",

  //chatlist
  "--chatlist-background-color": "rgb(255, 192, 203)",
  "--no-conversation-text-color": "rgb(255, 255, 255)",

  //chat item
  "--chatitem-background-color": "#47454c",
  "--chatitem-selected-background-color": "rgb(255, 255, 0)",
  "--chatitem-title-text-color": "white",
  "--chatitem-content-text-color": "white",
  "--chatitem-hover-color": "white",

  //message list
  "--messagelist-background-color": "#47454c",

  // incoming message
  "--incoming-message-text-color": "white",
  "--incoming-message-name-text-color": "rgb(255, 255, 255)",
  "--incoming-message-background-color": "#f9f9f936",
  "--incoming-message-timestamp-color": "rgb(255, 255, 255)",
  "--incoming-message-link-color": "white",

  //outgoing message
  "--outgoing-message-text-color": "white",
  "--outgoing-message-background-color": "#6ed587",
  "--outgoing-message-timestamp-color": "white",
  "--outgoing-message-checkmark-color": "white",
  "--outgoing-message-loader-color": "white",
  "--outgoing-message-link-color": "white",
};
